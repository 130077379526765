import React from 'react'
import styles from './styles.module.scss'

interface IInfoProps {
  startDate: string
  endDate: string
  numberOfVacationDays: number | undefined
  projectName?: string
  loading?: boolean
}

export const Info = ({
  startDate,
  endDate,
  projectName,
  numberOfVacationDays,
  loading,
}: IInfoProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!loading ? (
          <div className={styles.title}>
            Broj godišnjih odmora za {projectName} od <b>{startDate}</b> do <b>{endDate}</b>:
          </div>
        ) : (
          <div className={`${styles.title} ${styles.titleLoading}`}></div>
        )}
      </div>
      {!loading && numberOfVacationDays !== undefined ? (
        <div className={styles.vacationDays}>
          <span>{numberOfVacationDays}</span>
        </div>
      ) : (
        <div className={`${styles.vacationDays} ${styles.vacationDaysLoading}`}></div>
      )}
    </div>
  )
}

export default Info
