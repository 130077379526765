import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from 'components/private/home/timetracks/timetrack.module.scss'
import { Table } from 'reactstrap'

interface IProps {}

const TimeTrackSkeleton: React.FC<IProps> = (props: IProps) => {
  return (
    <>
      <div className={styles.trackPadding}>
        <h3>
          <Skeleton />
        </h3>
        <Table className={styles.table} style={{ opacity: 0.25 }}>
          <thead className={styles.thead}>
            <tr>
              <th>
                <Skeleton />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className={styles.trackPadding} style={{ opacity: 0.25 }}>
        <h3>
          <Skeleton />
        </h3>
        <Table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              <th>
                <Skeleton />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default TimeTrackSkeleton
