import React, { useState } from 'react'
import { Button, Col, Collapse, Container, Row } from 'reactstrap'
import { IMonth } from 'Types/TimeTrack/IMonth'
import styles from './styles.module.scss'
import arrowIcon from 'assets/img/arrow-icon.svg'
import { getAllMonths } from 'components/private/home/timetracks/utils/getAllMonths'
import moment from 'moment'
import { Filter } from 'Types/Filter'
import { ILabel } from 'Types/ILabel'
import userApi from 'services/userApi'
import UserSelector from 'components/private/home/timetracks/userSelector/UserSelector'
import { useEffect } from 'react'
import { normalizeDate } from 'Helpers/date'

interface IProps {
  setFilters: Function
  filters: Filter[]
  monthActive: boolean
}

const CURRENT_YEAR = moment().year()
const PREVIOUS_YEAR = moment().year() - 1

export const MonthSelector: React.FC<IProps> = (props: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [month, setMonth] = useState<IMonth>(new IMonth())
  const [year, setYear] = useState<number>(CURRENT_YEAR)
  const [users, setUsers] = useState<ILabel[]>()
  const [user, setUser] = useState<ILabel>()
  const [allMonthNames, setAllMonthNames] = useState<IMonth[]>([])
  let showAllMonths: IMonth = {
    name: 'all',
    start: normalizeDate(moment().startOf('year').toISOString()),
    end: normalizeDate(moment().endOf('year').toISOString()),
  }

  useEffect(() => {
    setAllMonthNames(getAllMonths(year))
  }, [year])

  useEffect(() => {
    if (props.monthActive) {
      let months = getAllMonths(CURRENT_YEAR)
      let thisMonth = moment().format('MMMM')
      // select current month
      handleMonthChange(months.find((el) => el.name === thisMonth)!)
    }
  }, [props.monthActive])

  function updateFilters(filter: Filter) {
    // if key already exists, update it
    if (props.filters.find((el) => el.key === filter.key)) {
      let modifyFilters = props.filters
      modifyFilters.find((el) => el.key === filter.key)!.value = filter.value
      props.setFilters([...modifyFilters])
    }
    // if new key just set it
    else {
      props.setFilters((fil: Filter[]) => [...fil, filter])
    }
  }

  function handleMonthChange(propMonth: IMonth) {
    const normalizedStartDate = normalizeDate(moment(propMonth.start, 'YYYY-MM-DD').toISOString())
    const normalizedEndDate = normalizeDate(moment(propMonth.end, 'YYYY-MM-DD').toISOString())
    setMonth({
      name: propMonth.name,
      start: normalizedStartDate,
      end: normalizedEndDate,
    })
    updateFilters({ key: 'startDate', value: normalizedStartDate })
    updateFilters({ key: 'endDate', value: normalizedEndDate })
    document.getElementById('arrowIcon')?.classList.remove(`${styles.active}`)
    setOpen(false)
  }

  async function getUsers() {
    const res = await userApi.getUsers()
    var userArray: ILabel[] = []
    res.forEach((user) => {
      userArray.push({ value: user.id, label: user.fullName })
    })
    setUsers(userArray)
  }

  function toggleOpen() {
    //calling getUsers method on first open
    !open && !users && getUsers()
    setOpen(!open)
    document.getElementById('arrowIcon')?.classList.toggle(`${styles.active}`)
  }

  function handleUserChange(e: ILabel) {
    setUser(e)
    let newFilters = props.filters.filter((el) => el.key !== 'userId')
    newFilters.push(new Filter('userId', e.value))
    props.setFilters([...newFilters])
    document.getElementById('arrowIcon')?.classList.remove(`${styles.active}`)
    setOpen(false)
  }

  return (
    <Container className={styles.container} fluid={true}>
      <img
        src={arrowIcon}
        id="arrowIcon"
        alt="arrow"
        className={styles.arrowIcon}
        onClick={toggleOpen}
      />
      <div style={{ width: '100%' }}>
        <Collapse id="collapse" isOpen={open}>
          <Row>
            <Col>
              <h4>Year</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={3} md={3} lg={2}>
              <Button
                className={styles.month}
                active={year === CURRENT_YEAR}
                onClick={() => setYear(CURRENT_YEAR)}
                type="button"
              >
                {CURRENT_YEAR}
              </Button>
            </Col>
            <Col xs={6} sm={3} md={3} lg={2}>
              <Button
                className={styles.month}
                active={year === PREVIOUS_YEAR}
                onClick={() => setYear(PREVIOUS_YEAR)}
                type="button"
              >
                {PREVIOUS_YEAR}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Month</h4>
            </Col>
          </Row>
          <Row>
            {allMonthNames.map((propMonth: IMonth, i) => {
              return (
                <Col xs={6} sm={3} md={3} lg={2} key={i}>
                  <Button
                    className={styles.month}
                    active={
                      props.monthActive
                        ? !month.name
                          ? propMonth.name === moment().format('MMMM')
                          : propMonth.name === month!.name
                        : propMonth.name === month!.name
                    }
                    onClick={() => handleMonthChange(propMonth)}
                    type="button"
                  >
                    {propMonth.name}
                  </Button>
                </Col>
              )
            })}
          </Row>
          <Row>
            <Col>
              <Button
                className={styles.allMonths}
                active={month.name === 'all'}
                onClick={() => handleMonthChange(showAllMonths)}
              >
                Show all available months
              </Button>
            </Col>
          </Row>
          <UserSelector users={users!} user={user!} handleUserChange={handleUserChange} />
        </Collapse>
      </div>
    </Container>
  )
}

export default MonthSelector
