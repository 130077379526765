import { IUser } from 'Types/IUser'
import { ITimetrack } from 'Types/TimeTrack/ITimeTrack'

export class Absence {
  id: number = 0
  absenceFrom: Date = new Date()
  absenceTo: Date = new Date()
  absenceReason: AbsenceReason | null = null
  user: IUser = {
    id: 0,
    email: '',
    fullName: '',
  }
}

export enum AbsenceReason {
  REMOTE = 'REMOTE',
  VACATION = 'VACATION',
  UNIVERSITY = 'UNIVERSITY',
  BOOKING_OFFICE = 'BOOKING_OFFICE',
  SICK_LEAVE = 'SICK_LEAVE',
}

export class AbsenceRequest {
  id?: number
  absenceFrom: Date = new Date()
  absenceTo: Date = new Date()
  absenceReason: AbsenceReason | null = null
  userId: string | null = null
}

export interface IUserAbsence {
  projectId: number
  projectName: string
  userVacation: IUserVacation[]
}
export interface ISickLeave {
  absences: IAbsence[]
  absenceReason: AbsenceReason
  user: IUser
}

export interface IUserVacation {
  absences: IAbsence[]
  user: IUser
}

export interface IAbsence {
  id: number
  absenceFrom: string
  absenceTo: string
  absenceReason: AbsenceReason
}

export interface IProjectMissingTimetrack {
  missingTimeTracks: string[]
  user: IUser
}
