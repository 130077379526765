import React from 'react'
import { Col, Row } from 'reactstrap'
import styles from './styles.module.scss'

interface IProps {
  header: {
    title: string
    buttonFunc?: any
    buttonTitle?: string
    icon?: string
    searchFunc?: any
  }
  children: any
}

export const GenericContentPage: React.FC<IProps> = (props: IProps) => {
  return (
    <>
      <Row className={styles.tableHeaderRow}>
        <Col className={styles.tableHeaderCol} style={{ justifyContent: 'flex-start' }}>
          <h3>{props.header.title}</h3>
        </Col>
        <Col className={styles.tableHeaderCol} style={{ justifyContent: 'flex-end' }}>
          {!props.header.buttonTitle ? null : (
            <div className={styles.searchWrapper}>
              {props.header.searchFunc ? (
                <div className={styles.searchCol}>
                  <input
                    className={styles.searchInput}
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      props.header.searchFunc(e.target.value)
                    }}
                  />
                </div>
              ) : null}
              <button
                className="addButton"
                type="button"
                onClick={() => {
                  props.header.buttonFunc(true)
                }}
              >
                <img className="addButtonIcon" src={props.header.icon} alt="Add icon" />
                {props.header.buttonTitle}
              </button>
            </div>
          )}
        </Col>
      </Row>
      {props.children}
    </>
  )
}

export default GenericContentPage
