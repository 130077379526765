import { LocalStorageKeyEnum } from 'localStorage.enum'
import { ILabel } from 'Types/ILabel'
import { CTimetrack, ITimetrackInterface, TimeTrackCreate } from 'Types/TimeTrack/ITimeTrack'

export function setITimetrack(props: TimeTrackCreate, projects: ILabel[]): CTimetrack {
  let newTimetrack: CTimetrack = new CTimetrack()
  newTimetrack.startDate = props.startDate
  newTimetrack.endDate = props.endDate
  newTimetrack.chargeableHours = props.chargeableHours!
  newTimetrack.description = props.description
  newTimetrack.id = props.id!
  newTimetrack.project.id = props.projectId!
  newTimetrack.projectRole = props.projectRole!
  newTimetrack.workingHours = props.workingHours!
  newTimetrack.project.projectName = projects.find((project) => project.value === props.projectId)!
    .label as string
  return newTimetrack
}

export function setTimetrackCreate(props: ITimetrackInterface): TimeTrackCreate {
  let newTimetrack = new TimeTrackCreate()
  newTimetrack.startDate = props.startDate
  newTimetrack.endDate = props.endDate
  newTimetrack.chargeableHours = props.chargeableHours
  newTimetrack.description = props.description
  newTimetrack.id = props.id && props.id
  newTimetrack.projectId = props.project.id
  newTimetrack.projectRole = props.projectRole
  newTimetrack.workingHours = props.workingHours
  newTimetrack.userId = localStorage.getItem(LocalStorageKeyEnum.USERID)
  return newTimetrack
}
