import { apiRoutes } from 'const/apiRoutes'
import { Filter } from 'Types/Filter'
import { ReportAbsence } from 'Types/Reports/ReportAbsence'
import { ReportTimeTrack } from 'Types/Reports/ReportTimeTrack'
import { api } from './api'

export default {
  getTimetracksReports(filters: Filter[]): Promise<ReportTimeTrack[]> {
    let query = ''

    if (filters.length !== 0) {
      query = makeQuery(filters)
    }

    let route = ''
    if (filters.length === 0) {
      route = apiRoutes.getTimeTrackReports
    } else {
      route = apiRoutes.getTimeTrackReports + query
    }
    return api.get(route)
  },

  getAbsencesReports(filters: Filter[]): Promise<ReportAbsence[]> {
    let query = ''

    if (filters.length !== 0) {
      query = makeQuery(filters)
    }

    let route = ''
    if (filters.length === 0) {
      route = apiRoutes.getAbsencesReports
    } else {
      route = apiRoutes.getAbsencesReports + query
    }
    return api.get(route)
  },

  exportWorkingHours(projectId: string, startDate: string, endDate: string): Promise<any> {
    return api.get(apiRoutes.getWorkingHoursPdf(projectId, endDate, startDate))
  },
}

function makeQuery(filters: Filter[]): string {
  let query = '?'

  filters.forEach((filter, i) => {
    if (i !== filters.length - 1) {
      query += filter.key + '=' + filter.value + '&'
    } else {
      query += filter.key + '=' + filter.value
    }
  })
  return query
}
