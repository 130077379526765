import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { Pageable } from 'Types/Pagination'

interface IProps {
  totalPages: number
  pageable: Pageable
  paginateData: (pageable: Pageable) => void
}

const Paginate: React.FC<IProps> = (props: IProps) => {
  const paginate = (pageNumber: number) => {
    const pageable: Pageable = {
      page: pageNumber,
      pageNumber: 0,
      pageSize: 10,
    }
    props.paginateData(pageable)
  }
  const goToFirstPage = () => {
    paginate(0)
  }

  const goToLastPage = () => {
    paginate(props.totalPages - 1)
  }

  const goToPreviousPage = () => {
    paginate(props.pageable.pageNumber! - 1)
  }

  const goToNextPage = () => {
    paginate(props.pageable.pageNumber! + 1)
  }

  const buildPagination = () => {
    let pagination: any = []

    if (props.totalPages === 2) {
      switch (props.pageable.pageNumber!) {
        case 1 - 1:
          pagination.push(
            <PaginationItem key={1} className="page-item active">
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          return pagination
        case 2 - 1:
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item active">
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          return pagination
        default:
          return pagination
      }
    } else if (props.totalPages === 3) {
      switch (props.pageable.pageNumber!) {
        case 1 - 1:
          pagination.push(
            <PaginationItem key={1} className="page-item active">
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 2 - 1:
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item active">
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          return pagination
        case 3 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item active">
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          return pagination
        default:
          return pagination
      }
    } else if (props.totalPages === 4) {
      switch (props.pageable.pageNumber!) {
        case 1 - 1:
          pagination.push(
            <PaginationItem key={1} className="page-item active">
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 2 - 1:
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item active">
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 3 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item active">
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          return pagination
        case 4 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item active">
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          return pagination
        default:
          return pagination
      }
    } else if (props.totalPages === 5) {
      switch (props.pageable.pageNumber!) {
        case 1 - 1:
          pagination.push(
            <PaginationItem key={1} className="page-item active">
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 2 - 1:
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item active">
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 3 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item active">
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 4 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item active">
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          return pagination
        case 5 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item active">
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          return pagination
      }
    } else if (props.totalPages === 6) {
      switch (props.pageable.pageNumber!) {
        case 1 - 1:
          pagination.push(
            <PaginationItem key={1} className="page-item active">
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={6} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">6</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 2 - 1:
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item active">
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={6} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">6</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 3 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={2} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">2</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item active">
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={6} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">6</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 4 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={3} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">3</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item active">
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={6} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">6</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">&raquo;</PaginationLink>
            </PaginationItem>
          )
          return pagination
        case 5 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={4} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">4</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item active">
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={6} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">6</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
              <PaginationLink next />
            </PaginationItem>
          )
          return pagination
        case 6 - 1:
          pagination.push(
            <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">&laquo;</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink previous />
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
              <PaginationLink className="page-link">1</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={5} className="page-item" onClick={goToPreviousPage}>
              <PaginationLink className="page-link">5</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={6} className="page-item active">
              <PaginationLink className="page-link">6</PaginationLink>
            </PaginationItem>
          )
          return pagination
      }
    } else if (props.totalPages >= 7) {
      if (props.pageable.pageNumber! === 1 - 1) {
        pagination.push(
          <PaginationItem key={1} className="page-item active">
            <PaginationLink className="page-link">1</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={2} className="page-item" onClick={goToNextPage}>
            <PaginationLink className="page-link">2</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={props.totalPages} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
            <PaginationLink next />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">&raquo;</PaginationLink>
          </PaginationItem>
        )
        return pagination
      } else if (props.pageable.pageNumber! === 2 - 1) {
        pagination.push(
          <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink previous />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">1</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={2} className="page-item active">
            <PaginationLink className="page-link">2</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={3} className="page-item" onClick={goToNextPage}>
            <PaginationLink className="page-link">3</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={props.totalPages} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
            <PaginationLink next />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">&raquo;</PaginationLink>
          </PaginationItem>
        )
        return pagination
      } else if (props.pageable.pageNumber! === 3 - 1) {
        pagination.push(
          <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">&laquo;</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink previous />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">1</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={2} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink className="page-link">2</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={3} className="page-item active">
            <PaginationLink className="page-link">3</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={4} className="page-item" onClick={goToNextPage}>
            <PaginationLink className="page-link">4</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={props.totalPages} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
            <PaginationLink next />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">&raquo;</PaginationLink>
          </PaginationItem>
        )
        return pagination
      } else if (props.pageable.pageNumber! === 4 - 1) {
        pagination.push(
          <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">&laquo;</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink previous />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">1</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...1'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={3} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink className="page-link">3</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={4} className="page-item active">
            <PaginationLink className="page-link">4</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={5} className="page-item" onClick={goToNextPage}>
            <PaginationLink className="page-link">5</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={props.totalPages} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
            <PaginationLink next />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">&raquo;</PaginationLink>
          </PaginationItem>
        )
        return pagination
      } else if (
        4 - 1 < props.pageable.pageNumber! &&
        props.pageable.pageNumber! < props.totalPages - 1
      ) {
        pagination.push(
          <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">&laquo;</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink previous />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">1</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...1'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )

        pagination.push(
          <PaginationItem
            key={props.pageable.pageNumber!}
            className="page-item"
            onClick={goToPreviousPage}
          >
            <PaginationLink className="page-link">{props.pageable.pageNumber!}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={props.pageable.pageNumber! + 1} className="page-item active">
            <PaginationLink className="page-link">{props.pageable.pageNumber! + 1}</PaginationLink>
          </PaginationItem>
        )
        //CHECK
        if (props.pageable.pageNumber! + 1 === props.totalPages - 1 - 1) {
          pagination.push(
            <PaginationItem key={props.totalPages - 1} className="page-item" onClick={goToNextPage}>
              <PaginationLink className="page-link">{props.totalPages - 1}</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={props.totalPages} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
            </PaginationItem>
          )
        } else if (props.pageable.pageNumber! + 1 < props.totalPages - 1 - 1) {
          pagination.push(
            <PaginationItem
              key={props.pageable.pageNumber! + 2}
              className="page-item"
              onClick={goToNextPage}
            >
              <PaginationLink className="page-link">
                {props.pageable.pageNumber! + 2}
              </PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={'...2'} className="page-item">
              <PaginationLink className="page-link text-muted">...</PaginationLink>
            </PaginationItem>
          )
          pagination.push(
            <PaginationItem key={props.totalPages} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
            </PaginationItem>
          )
        } else if (props.pageable.pageNumber! + 1 > props.totalPages - 1 - 1) {
          pagination.push(
            <PaginationItem key={props.totalPages} className="page-item" onClick={goToLastPage}>
              <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
            </PaginationItem>
          )
        }
        //END CHECK
        pagination.push(
          <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
            <PaginationLink next />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'last'} className="page-item" onClick={goToLastPage}>
            <PaginationLink className="page-link">&raquo;</PaginationLink>
          </PaginationItem>
        )
        return pagination
      } else if (props.pageable.pageNumber! === props.totalPages - 1 - 1) {
        pagination.push(
          <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">&laquo;</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink previous />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">1</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem
            key={props.pageable.pageNumber!}
            className="page-item"
            onClick={goToPreviousPage}
          >
            <PaginationLink className="page-link">{props.pageable.pageNumber!}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={props.pageable.pageNumber! + 1} className="page-item active">
            <PaginationLink className="page-link">{props.pageable.pageNumber! + 1}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem
            key={props.pageable.pageNumber! + 2}
            className="page-item"
            onClick={goToLastPage}
          >
            <PaginationLink className="page-link">{props.pageable.pageNumber! + 2}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'next'} className="page-item" onClick={goToNextPage}>
            <PaginationLink next />
          </PaginationItem>
        )
      } else if (props.pageable.pageNumber! === props.totalPages - 1) {
        pagination.push(
          <PaginationItem key={'first'} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">&laquo;</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'prev'} className="page-item" onClick={goToPreviousPage}>
            <PaginationLink previous />
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={1} className="page-item" onClick={goToFirstPage}>
            <PaginationLink className="page-link">1</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={'...'} className="page-item">
            <PaginationLink className="page-link text-muted">...</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem
            key={props.totalPages - 1}
            className="page-item"
            onClick={goToPreviousPage}
          >
            <PaginationLink className="page-link">{props.totalPages - 1}</PaginationLink>
          </PaginationItem>
        )
        pagination.push(
          <PaginationItem key={props.totalPages} className="page-item active">
            <PaginationLink className="page-link">{props.totalPages}</PaginationLink>
          </PaginationItem>
        )
      }
    }
    return pagination
  }

  let pagination = buildPagination()

  return (
    <div style={{ margin: '0 auto' }}>
      <Pagination aria-label="pagination">{pagination}</Pagination>
    </div>
  )
}

export default Paginate
