import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { ReportTimeTrack } from 'Types/Reports/ReportTimeTrack'
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap'
import Select from 'react-select'
import { ILabel } from 'Types/ILabel'
import clientApi from 'services/clientApi'
import { setLabel } from 'components/utils/SelectFunctions'
import userApi from 'services/userApi'
import projectApi from 'services/projectApi'
import * as Yup from 'yup'
import { TimelineGroupBase, TimelineItemBase } from 'react-calendar-timeline'
import { Moment } from 'moment'
import { Filter } from 'Types/Filter'

interface IProps {
  setFilters: (data: any) => void
  filters: Filter[]
  setLoading: (loading: boolean) => void
  users: ILabel[]
}

const FilterForm: React.FC<IProps> = (props: IProps) => {
  const [clientsArray, setClientsArray] = useState<ILabel[]>()
  const [projectsArray, setProjectsArray] = useState<ILabel[]>()
  const [usersArray, setUsersArray] = useState<ILabel[]>()

  let client: ILabel
  let project: ILabel
  let employee: ILabel

  const [clientClicked, setClientClicked] = useState<boolean>(false)
  const [projectClicked, setProjectClicked] = useState<boolean>(false)
  const [userClicked, setUserClicked] = useState<boolean>(false)

  useEffect(() => {
    setUsersArray(props.users)
    getClients()
    getProjects()
  }, [])

  function getClients() {
    clientApi.getClients().then((response) => {
      setClientsArray(setLabel(response, 'clientName'))
    })
  }

  function getProjects() {
    projectApi.getProjects().then((response) => {
      setProjectsArray(setLabel(response.content, 'projectName'))
    })
  }

  async function getFilteredReports(e: ILabel, caseName: string) {
    props.setLoading(true)
    const index = props.filters.findIndex((obj) => obj.key === caseName)
    if (index !== -1) {
      const newFilters = props.filters.filter((_, i) => i !== index)
      props.setFilters(newFilters)
    }
    switch (caseName) {
      case 'userId':
      case 'clientId':
      case 'projectId':
        {
          if (caseName && e) {
            props.setFilters((oldArray: Filter[]) => [
              ...oldArray,
              new Filter(caseName, e.value, e.label),
            ])
          }
        }
        break
    }
    props.setLoading(false)
  }

  function manageDisabledSelect(selectedType: string) {
    if (selectedType === 'client') {
      setClientClicked(true)
    } else if (selectedType === 'project') {
      setProjectClicked(true)
    } else {
      setUserClicked(true)
    }
  }

  function clearAllFilters() {
    setClientClicked(false)
    setProjectClicked(false)
    setUserClicked(false)
    props.setFilters([])
  }

  const ReportsTimeTrackSchema = Yup.object().shape({
    userFullName: Yup.string().required("User's name is required"),
    clientName: Yup.string().required('Client name is required'),
    projectName: Yup.string().required('Project name is required'),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={new ReportTimeTrack()}
      onSubmit={async (values: any) => {
        console.log(values)
      }}
      validationSchema={ReportsTimeTrackSchema}
    >
      {(props2) => {
        const { handleBlur } = props2
        return (
          <Form>
            <Row className="tableHeaderRow">
              <Col sm={12} md={4}>
                <FormGroup>
                  <Label className="modalLabel" for="clientName">
                    Client
                  </Label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 100 }),
                    }}
                    isClearable
                    isDisabled={projectClicked || userClicked}
                    options={clientsArray}
                    value={client}
                    onChange={(e: any, triggeredAction) => {
                      getFilteredReports(e, 'clientId')
                      manageDisabledSelect('client')
                      if (triggeredAction.action === 'clear') {
                        // Clear happened
                        clearAllFilters()
                      }
                    }}
                    onBlur={handleBlur}
                    placeholder="Select client"
                    name="clientName"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={4}>
                <FormGroup>
                  <Label className="modalLabel" for="projectName">
                    Project
                  </Label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 100 }),
                    }}
                    isClearable
                    isDisabled={clientClicked || userClicked}
                    options={projectsArray}
                    value={project}
                    onChange={(e: any, triggeredAction) => {
                      getFilteredReports(e, 'projectId')
                      manageDisabledSelect('project')
                      if (triggeredAction.action === 'clear') {
                        // Clear happened
                        clearAllFilters()
                      }
                    }}
                    onBlur={handleBlur}
                    placeholder="Select project"
                    name="projectName"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={4}>
                <FormGroup>
                  <Label className="modalLabel" for="userFullName">
                    Employee
                  </Label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 100 }),
                    }}
                    isClearable
                    isDisabled={projectClicked || clientClicked}
                    options={usersArray}
                    value={employee}
                    onChange={(e: any, triggeredAction) => {
                      getFilteredReports(e, 'userId')
                      manageDisabledSelect('user')
                      if (triggeredAction.action === 'clear') {
                        // Clear happened
                        clearAllFilters()
                      }
                    }}
                    onBlur={handleBlur}
                    placeholder="Select employee"
                    name="userFullName"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
export default FilterForm
