import { apiRoutes } from 'const/apiRoutes'
import { LocalStorageKeyEnum } from 'localStorage.enum'
import { Authentication } from 'Types/Authentication/Authentication'
import { api } from './api'

export default {
  logout(): void {
    localStorage.removeItem(LocalStorageKeyEnum.TOKENID)
    localStorage.removeItem(LocalStorageKeyEnum.USERID)
    localStorage.removeItem(LocalStorageKeyEnum.JWTTOKEN)
  },

  login(token: string): Promise<Authentication> {
    return api.post(apiRoutes.login + '?token_id=' + token, token)
  },
}
