import React, { useState } from 'react'
import styles from './styles.module.scss'
import { GoogleLogin, GoogleLoginResponse } from 'react-google-login'
import { LocalStorageKeyEnum } from 'localStorage.enum'
import { gapi } from 'gapi-script'
import authentication from 'services/authentication'
import LoadingSpinner from './loadingSpinner/LoadingSpinner'
import { useNavigate } from 'react-router-dom'

const Landing: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID

  async function responseGoogle(response: GoogleLoginResponse | any) {
    setLoading(true)
    const auth2 = gapi.auth2.getAuthInstance()
    console.log('REACT_APP_NODE_ENV valuee is --> ' + process.env.REACT_APP_NODE_ENV)
    console.log('TEST_ENV variable is --> ' + process.env.REACT_APP_TEST_VARIABLE)
    if (
      (auth2 !== null && response.accessToken) ||
      !localStorage.getItem(LocalStorageKeyEnum.TOKENID)
    ) {
      const autash2 = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token
      localStorage.setItem(LocalStorageKeyEnum.TOKENID, autash2)
      await authentication.login(autash2).then((r) => {
        localStorage.setItem(LocalStorageKeyEnum.USERID, r.user.id.toString())
        localStorage.setItem(LocalStorageKeyEnum.JWTTOKEN, r.jwtToken)
        localStorage.setItem(LocalStorageKeyEnum.ADMIN, r.user.admin.toString())
        setLoading(false)
        navigate('/timetracker')
      })
    } else {
      setLoading(false)
    }
  }

  return (
    <div className={styles.landing}>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <div className={styles.logo}>
            <span>
              brain<span>hr</span>
            </span>
          </div>
          <GoogleLogin
            clientId={`${REACT_APP_CLIENT_ID}`}
            buttonText="Sign in with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            className={styles.signInButton}
            disabled={loading}
          />
          {loading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  )
}

export default Landing
