import { ReactNode } from 'react'
import { AbsenceReason } from 'Types/Absences/Absence'

interface ITableDate {
  userId: number | string
  type: 'SICK_LEAVE' | 'MISSING_TIMETRACK' | 'VACATION'
  children: ReactNode
}
const TableDate = ({ userId, type, children }: ITableDate) => {
  return (
    <td
      key={`${userId}-td`}
      className={`timeline-item-${type}`}
      style={{
        padding: '6px 8px',
        borderRadius: '3px',
        color: '#333',
        fontWeight: 500,
      }}
    >
      {children}
    </td>
  )
}

export default TableDate
