import moment, { Moment } from 'moment'
import React from 'react'
import 'react-calendar-timeline/lib/Timeline.css'
import Timeline, { TimelineGroupBase, TimelineItemBase, TodayMarker } from 'react-calendar-timeline'

interface IProps {
  users: TimelineGroupBase[]
  absences: TimelineItemBase<Moment>[]
  setDeleteModal: (open: boolean) => void
  setDeleteData: (data: any) => void
}

export const CustomTimeline: React.FC<IProps> = (props: IProps) => {
  return (
    <Timeline
      groups={props.users}
      items={props.absences}
      stackItems
      canMove={false}
      canChangeGroup={false}
      canResize={false}
      itemTouchSendsClick={false}
      onItemClick={(itemId) => (props.setDeleteModal(true), props.setDeleteData({ id: itemId }))}
      defaultTimeStart={moment().startOf('day')}
      defaultTimeEnd={moment().add(5, 'days')}
    >
      <TodayMarker date={moment().toDate()}>
        {({ styles }) => {
          const customStyles = {
            ...styles,
            backgroundColor: 'red',
            width: '2px',
          }
          return <div style={customStyles} />
        }}
      </TodayMarker>
    </Timeline>
  )
}

export default CustomTimeline
