import moment from 'moment-business-days'
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { getWorkingDays, setMonthlyWorkingHours } from 'components/utils/WorkFunctions'
import { ITimetrackInterface } from 'Types/TimeTrack/ITimeTrack'
import { IHoliday } from 'Types/Holidays/IHoliday'
import holidays from 'Helpers/holidays'
import absenceApi from 'services/absenceApi'
import { LocalStorageKeyEnum } from 'localStorage.enum'
import { AbsenceReason } from 'Types/Absences/Absence'

interface IProps {
  timetracks: Array<ITimetrackInterface[]>
  holidays: IHoliday[] | undefined
}

export const WorkingDays: React.FC<IProps> = (props: IProps) => {
  const [workingDatesArray, setWorkingDatesArray] = useState<Array<string>>()
  const [userDates, setUserDates] = useState<Array<string>>([])

  useEffect(() => {
    getAndSetWorkingDatesArray()
    setDatesForUser()
  }, [props.timetracks])

  function getAndSetWorkingDatesArray() {
    let res = getWorkingDays().dateArray.filter((date) =>
      props.holidays?.every((holiday) => moment(holiday.date).format('DD-MM-YYYY') !== date)
    )
    setWorkingDatesArray(res)
  }

  async function getVacationDaysForUser() {
    try {
      const userId = localStorage.getItem(LocalStorageKeyEnum.USERID)!.toString()
      const response = await absenceApi.getAbsencesForUser(userId)
      return response
    } catch {}
  }

  async function setDatesForUser() {
    let userWorkingHoursArray: string[] = setMonthlyWorkingHours(props.timetracks).dates
    const startDate = moment(props.timetracks[0][0].startDate).startOf('month')
    const endDate = moment(props.timetracks[0][0].startDate).endOf('month')

    let m
    if (moment().format('MMMM') !== moment(props.timetracks[0][0].startDate).format('MMMM')) {
      m = moment(startDate).monthBusinessDays(endDate)
    } else {
      m = moment().monthBusinessDays(moment())
    }

    let dates: string[] = []
    m.length &&
      m.forEach((date) => {
        dates.push(moment(date).format('DD.MM.YYYY.'))
      })
    var userVacationDays = await getVacationDaysForUser()
    const currentDateUserAbsences = userVacationDays?.filter(
      (absence) =>
        moment(absence.absenceFrom).isAfter(startDate) &&
        moment(absence.absenceTo).isBefore(endDate) &&
        (absence.absenceReason === AbsenceReason.VACATION ||
          absence.absenceReason === AbsenceReason.SICK_LEAVE)
    )
    userWorkingHoursArray &&
      setUserDates(
        dates
          ?.filter((date) => !userWorkingHoursArray.includes(date))
          .filter(
            (date) =>
              props.holidays?.every(
                (holiday) =>
                  moment(holiday.date).format('DD.MM.YYYY') !== moment(date).format('DD.MM.YYYY')
              ) &&
              !currentDateUserAbsences?.find(
                (absence) => moment(absence.absenceFrom).format('DD.MM.YYYY.') == date
              )
          )
      )
  }

  return (
    <>
      {userDates.length ? (
        <div className={styles.container}>
          <div className={styles.header}>
            {userDates && (
              <span className={styles.title}>
                You haven't filled {userDates && userDates!.length}{' '}
                {userDates && userDates.length === 1 ? 'day' : 'days'}:
              </span>
            )}
            {!userDates && <span className={styles.title}>You have filled all working days.</span>}
            <span className={styles.percentage}>
              {userDates && userDates!.length}{' '}
              {userDates && userDates.length === 1 ? 'day' : 'days'}
            </span>
          </div>
          {userDates && (
            <div className={styles.dates}>
              {userDates &&
                userDates.map((date, i) => {
                  return (
                    <span className={styles.completedHours} key={i}>
                      {date}
                    </span>
                  )
                })}
            </div>
          )}
          {workingDatesArray && (
            <span className={styles.workingHours}>
              This month has <b>{workingDatesArray?.length}</b> working days
            </span>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default WorkingDays
