import axios from 'axios'
import lsh from '../Helpers/localStorage'
import authenticationService from 'services/authentication'
//deployment
const LOCAL_URL = 'http://localhost:8080/'
const DEV_URL = 'https://brainhr-dev.azurewebsites.net/'
const PROD_URL = 'https://hr.brainit.agency/'

const baseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') return PROD_URL
  else if (process.env.REACT_APP_NODE_ENV === 'development') return DEV_URL
  else return LOCAL_URL
}

export const api = axios.create({
  baseURL: baseUrl(),
  headers: {
    'Content-Type': 'application/json',
    mode: 'no-cors',
    'Access-Control-Allow-Origin': '*',
  },
})

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let errorMessage: string | undefined = 'Error occurred.'
    if (error.response) {
      errorMessage = error.response.data.details
    }
    return Promise.reject(new Error(errorMessage))
  }
  // error => {
  //   var errorMessege: string | undefined = undefined;
  //   if (error.response) {
  //     if (typeof error.response.data === "string" && error.response.data.length > 0) {
  //       errorMessege = error.response.data
  //     } else if (error.response.data.errors !== undefined && error.response.data.errors[0] !== undefined) {
  //       errorMessege = error.response.data.errors[0].description
  //     }
  //     // big errors usually indicate it is not a custom message but C# error, we don't wanna return those
  //     if (errorMessege === undefined || errorMessege.length > 100) {
  //       errorMessege = "There was an issue with your request!";
  //     }
  //   }
  //   return Promise.reject(new Error(errorMessege));
  //})
)

axios.interceptors.request.use((config: any) => {
  if (lsh.getToken() !== undefined && lsh.getToken() != null) {
    //axios.defaults.headers['Authorization'] = 'Bearer ' + lsh.getToken()
    if (lsh.isTokenExpired()) authenticationService.logout()
  }
  return config
})

export default axios
