import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Collapse, Container, Form, FormGroup, Label, Row, Table } from 'reactstrap'
import { Component } from 'react'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import userApi from 'services/userApi'
import { IMeeting } from 'Types/Meeting/Meeting'
import addIcon from 'assets/img/add-icon.svg'
import meetingApi from 'services/meetingApi'
import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'
import Collapsible from './Collapsible'
import MeetingCreateModel from './MeetingCreateModal'
import { IUser } from 'Types/IUser'
import DeleteModal from 'components/ui/modals/DeleteModal'

const Meeting = (props: any) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [meetingData, setMeetingData] = useState<IMeeting>()
  const [loading, setLoading] = useState<boolean>(true)
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [meetings, setMeetings] = useState<IMeeting[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const snackbarContext = useContext(SnackbarContext)

  useEffect(() => {
    setIsAdmin(localStorage.getItem('IS_ADMIN') === 'true' ? true : false)
    // TODO -- ovo treba nekako bolje
    try {
      let userId = props.location.state.userId
      if (userId) localStorage.setItem('USER_MEETING_ID', userId)
    } catch (e) {}

    getUserMeetings()
    getAllUsers()
  }, [])

  function getUserMeetings() {
    try {
      let userMeetingId = localStorage.getItem('USER_MEETING_ID')
      if (userMeetingId) {
        //console.log("Employee id -->" + userMeetingId);
        meetingApi.getUserAndSupervisorMeetings(userMeetingId).then((response) => {
          setMeetings(response)
        })
      }
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  function getAllUsers() {
    try {
      userApi.getUsers().then((response) => {
        setUsers(response)
      })
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function createNewMeeting(userId: number) {
    try {
      //await userApi.makeNewEmployeeSupervisorPair(userId, Number(localStorage.getItem("USERID")));
      //await getSupervisorSubordinates();
      snackbarContext?.setShowSnackbarData(true, 'Meeting created successfully.', 'success')
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function deleteMeeting(meetingId: number) {
    try {
      setLoading(true)
      await meetingApi.deleteMeeting(meetingId)

      await getUserMeetings()
      snackbarContext?.setShowSnackbarData(true, 'Meeting deleted', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <GenericContentPage
        header={
          localStorage.getItem('USER_MEETING_ID') == localStorage.getItem('USERID')
            ? {
                title: 'Meetings',
                buttonFunc: setCreateModal,
                buttonTitle: 'New Meeting',
                icon: addIcon,
              }
            : {
                title: 'Meetings',
              }
        }
      >
        {' '}
        <>
          <Row>
            {meetings.slice(0).map((meeting, i) => {
              return (
                <Collapsible
                  key={meeting.id}
                  meeting={meeting}
                  setDeleteModal={setDeleteModal}
                  setDeleteData={setMeetingData}
                />
              )
            })}
          </Row>

          <MeetingCreateModel
            isOpen={createModal}
            setCreateModal={setCreateModal}
            employeeData={users}
            makeNewMeeting={createNewMeeting}
            getUserMeetings={getUserMeetings}
          />
          <DeleteModal
            isOpen={deleteModal}
            setDeleteModal={setDeleteModal}
            setDeleteData={setMeetingData}
            data={meetingData}
            delete={deleteMeeting}
          />
        </>
      </GenericContentPage>
    </Container>
  )
}

export default Meeting
