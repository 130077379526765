import { apiRoutes } from 'const/apiRoutes'
import PaginationHelper from 'Helpers/PaginationHelper'
import SortFilterHelper from 'Helpers/SortFilterHelper'
import { Pageable } from 'Types/Pagination'
import { IProjectCreate } from 'Types/Project/IProjectCreate'
import { IProjectGet } from 'Types/Project/IProjectGet'
import { SortFilter } from 'Types/SortFilter'
import { api } from './api'

export default {
  getProjects(): Promise<any> {
    return api.get(apiRoutes.getProjects)
  },
  getProjectsPageeable(pageable?: Pageable, sortFilters?: SortFilter[]): Promise<any> {
    return api.get(
      apiRoutes.getProjects +
        PaginationHelper.constructPagination(pageable!) +
        SortFilterHelper.constructSortFilters(sortFilters!)
    )
  },

  getProject(projectId: number): Promise<IProjectGet> {
    return api.get(apiRoutes.getProject(projectId))
  },

  createProject(project: IProjectCreate): Promise<any> {
    return api.post(apiRoutes.createProject, project)
  },

  deleteProject(projectId: number): Promise<any> {
    return api.delete(apiRoutes.deleteProject(projectId))
  },

  updateProject(projectId: number, data: IProjectCreate): Promise<any> {
    return api.put(apiRoutes.updateProject(projectId), data)
  },

  searchProjects(searchValue: string): Promise<any> {
    return api.get(apiRoutes.searchProjects(searchValue))
  },

  getUserProjects(userId: string): Promise<any> {
    return api.get(apiRoutes.getUserProjects(userId))
  },
  // editServiceType(form: ServiceType): Promise<any> {
  //   return axios.put(serviceTypeApi, form);
  // },

  // fetchServiceTypes(orgId: number): Promise<any> {
  //   return axios.get(serviceTypeApi + "/" + orgId);
  // },
}
