import React from 'react'
import { Col, Row, FormGroup } from 'reactstrap'
import Select from 'react-select'
import { ILabel } from 'Types/ILabel'

interface IProps {
  users: ILabel[]
  user: ILabel
  handleUserChange: (user: ILabel) => void
}

export const UserSelector: React.FC<IProps> = (props: IProps) => {
  return (
    <Row>
      <Col lg={4} xs={12}>
        <FormGroup className="mt-3">
          <Select
            value={props.user}
            placeholder="Select employee..."
            options={props.users}
            name="clientId"
            onChange={(e) => e && props.handleUserChange(e)}
            type="text"
          />
        </FormGroup>
      </Col>
    </Row>
  )
}

export default UserSelector
