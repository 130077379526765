import Sidebar from 'components/ui/sidebar/Sidebar'
import styles from './routes.module.scss'
import sidebarStyles from 'components/ui/sidebar/sidebar.module.scss'
import { Container } from 'reactstrap'

const Layout = ({ children }: any) => {
  const toggleSidebar = () => {
    document.getElementById('sidebar')?.classList.toggle(`${sidebarStyles.collapsed}`)
  }
  return (
    <div className={styles.Root}>
      <Sidebar />
      {/* <Container className="contentWrap"> */}
      <div className={styles.burgerWrapper} onClick={toggleSidebar}>
        <span className={styles.burgerLine}></span>
        <span className={styles.burgerLine}></span>
        <span className={styles.burgerLine}></span>
      </div>
      {/* </Container> */}
      <Container className="contentWrap">{children}</Container>
    </div>
  )
}

export default Layout
