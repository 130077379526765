import { apiRoutes } from 'const/apiRoutes'
import { api } from './api'
import { IHoliday } from 'Types/Holidays/IHoliday'

export default {
  getHolidaysForCurrentYear(): Promise<IHoliday> {
    return api.get(apiRoutes.getHolidaysForCurrentYear())
  },
  getAllHolidaysBetweenDates(startDate: string, endDate: string): Promise<IHoliday[]> {
    return api.get(apiRoutes.getAllHolidaysBetweenDates(startDate, endDate))
  },
}
