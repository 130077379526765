import React from 'react'
import { isAuthenticated } from 'components/utils/AuthFunctions'
import sidebarStyles from 'components/ui/sidebar/sidebar.module.scss'
import { Navigate } from 'react-router-dom'

interface IPrivateRoute {
  children: any
}
const PrivateRoute = ({ children }: IPrivateRoute): JSX.Element => {
  return <>{isAuthenticated() ? children : <Navigate to="/" />}</>
}

export default PrivateRoute
