import { SortFilter } from 'Types/SortFilter'

export default {
  constructSortFilters: (filters: SortFilter[]) => {
    if (filters.length === 0) {
      return ''
    } else {
      return makeQuery(filters)
    }
  },
}

function makeQuery(filters: SortFilter[]): string {
  let query = '&'

  filters.forEach((filter, i) => {
    if (i !== filters.length - 1) {
      query += 'sort=' + filter.key + ',' + filter.value + '&'
    } else {
      query += 'sort=' + filter.key + ',' + filter.value
    }
  })
  return query
}
