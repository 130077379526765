import moment from 'moment'
import { ITimetrackInterface } from 'Types/TimeTrack/ITimeTrack'

export function getWorkingDays(selectedEndOfMonth?: any, holidays?: string[]) {
  let dateArray: string[] = []
  moment.updateLocale('hr', {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidayFormat: 'DD-MM-YYYY',
    holidays: holidays,
    months: [
      'Siječanj',
      'Veljača',
      'Ožujak',
      'Travanj',
      'Svibanj',
      'Lipanj',
      'Srpanj',
      'Kolovoz',
      'Rujan',
      'Listopad',
      'Studeni',
      'Prosinac',
    ],
  })

  var workingDatesArray = moment().monthBusinessDays()

  //setting array to working days date in exact format
  workingDatesArray.map((date) => dateArray.push(moment(date).format('DD-MM-YYYY')))
  //setting number of working days to state
  let workingDaysArray = moment(selectedEndOfMonth, 'DD-MM-YYYY').businessDaysIntoMonth()
  return { dateArray, workingDaysArray }
}

export function setMonthlyWorkingHours(timetracks: ITimetrackInterface[][]) {
  let hours: number = 0
  let dates: string[] = []
  timetracks.forEach((timetrack) => {
    timetrack.forEach((day: ITimetrackInterface) => {
      dates.push(moment(day.startDate).format('DD.MM.YYYY.'))
      hours = hours + day.workingHours
    })
  })
  return { hours, dates }
}
