import { getWorkingDays } from 'components/utils/WorkFunctions'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ITimetrackInterface } from 'Types/TimeTrack/ITimeTrack'
import styles from './styles.module.scss'
import { IHoliday } from 'Types/Holidays/IHoliday'

interface IProps {
  timetracks: Array<ITimetrackInterface[]>
  workingHours: number | undefined
  holidays: IHoliday[] | undefined
}

export const WorkingHours: React.FC<IProps> = (props: IProps) => {
  const [workingDays, setWorkingDays] = useState<number>()

  useEffect(() => {
    setWorkingDays(
      getWorkingDays(
        moment(props.timetracks[0][0].startDate).endOf('month').format('DD-MM-YYYY'),
        props.holidays?.map((h) => moment(h.date).format('DD-MM-YYYY'))
      ).workingDaysArray
    )
  }, [props.timetracks])

  function setWorkingPercentage() {
    return Number(
      props.workingHours &&
        workingDays &&
        ((props.workingHours / (workingDays * 8)!) * 100).toPrecision(2)
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>Working hours</span>
        <span className={styles.percentage}>{setWorkingPercentage()}%</span>
      </div>
      {props.workingHours && <span className={styles.completedHours}>{props.workingHours}h</span>}
      {workingDays && (
        <span className={styles.workingHours}>
          This month has <b>{workingDays * 8}</b> working hours
        </span>
      )}
    </div>
  )
}

export default WorkingHours
