import React, { FunctionComponent } from 'react'
import styles from 'components/ui/sidebar/sidebar.module.scss'
import authentication from 'services/authentication'
import { Action } from 'components/ui/sidebar/action/Action'
import { ReactComponent as reportsIcon } from 'assets/img/actionIcon/ic-assignment.svg'
import { ReactComponent as signOutIcon } from 'assets/img/actionIcon/ic-exit-to-app.svg'
import { ReactComponent as timelineIcon } from 'assets/img/actionIcon/ic-linear-scale.svg'
import { ReactComponent as timeIcon } from 'assets/img/actionIcon/ic-timer.svg'
import { ReactComponent as clientsIcon } from 'assets/img/actionIcon/ic-people.svg'
import { ReactComponent as projectIcon } from 'assets/img/actionIcon/ic-list.svg'
import { ReactComponent as supervisorIcon } from 'assets/img/actionIcon/ic-people.svg'
import { useNavigate } from 'react-router-dom'
import { LocalStorageKeyEnum } from 'localStorage.enum'

interface IProps {
  action: {
    icon: FunctionComponent<React.SVGProps<SVGSVGElement>>
    text: string
    path: string
  }
}

export const Sidebar: React.FC = () => {
  const navigate = useNavigate()
  const isAdmin = localStorage.getItem(LocalStorageKeyEnum.ADMIN)

  //{icon: dashboardIcon, text: "Dashboard", path: "/dashboard"}, add to menuIconTextArray if needed
  let menuIconTextArray = [
    { icon: timeIcon, text: 'Tracker', path: '/timetracker' },
    { icon: projectIcon, text: 'Projects', path: '/projects' },
    { icon: clientsIcon, text: 'Clients', path: '/clients' },
    { icon: timelineIcon, text: 'Absences', path: '/timeline' },
    { icon: reportsIcon, text: 'Reports', path: '/reports' },
    { icon: supervisorIcon, text: 'Supervisor', path: '/supervisor' },
  ]
  let menuIconTextArrayAdmin = [
    { icon: timeIcon, text: 'Tracker', path: '/timetracker' },
    { icon: timeIcon, text: 'Tracker Details', path: '/timetracker-details' },
    { icon: projectIcon, text: 'Projects', path: '/projects' },
    { icon: clientsIcon, text: 'Clients', path: '/clients' },
    { icon: timelineIcon, text: 'Absences', path: '/timeline' },
    { icon: reportsIcon, text: 'Reports', path: '/reports' },
    { icon: supervisorIcon, text: 'Supervisor', path: '/supervisor' },
  ]

  function collapseSidebar() {
    document.getElementById('sidebar')!.classList.remove(`${styles.collapsed}`)
  }

  function logout() {
    authentication.logout()
    navigate('/')
  }

  const menu = isAdmin && JSON.parse(isAdmin) ? menuIconTextArrayAdmin : menuIconTextArray
  return (
    <div className={styles.sidebarWrapper} id="sidebar">
      <div className={styles.sidebar}>
        <ul>
          <li className={styles.logo}>
            <span>
              brain<span>hr</span>
            </span>
          </li>

          <div className={styles.gridItem}>
            {menu.map((action, i) => {
              return (
                <li className={styles.gridAction} key={i} onClick={() => collapseSidebar()}>
                  <Action action={action} key={i}></Action>
                </li>
              )
            })}
          </div>
          <div className={styles.logout} onClick={logout}>
            <Action action={{ icon: signOutIcon, text: 'Sign Out', path: '/' }}></Action>
          </div>
          <span className={styles.version}>v1.1.5</span>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
