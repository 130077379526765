import { apiRoutes } from 'const/apiRoutes'
import PaginationHelper from 'Helpers/PaginationHelper'
import SortFilterHelper from 'Helpers/SortFilterHelper'
import { IClientCreateOrUpdate } from 'Types/Client/IClientCreateOrUpdate'
import { Pageable } from 'Types/Pagination'
import { SortFilter } from 'Types/SortFilter'
import { api } from './api'

export default {
  getClients(): Promise<any> {
    return api.get(apiRoutes.getClients)
  },

  getClientsPageable(pageable?: Pageable, sortFilters?: SortFilter[]): Promise<any> {
    return api.get(
      apiRoutes.getClientsPageable +
        PaginationHelper.constructPagination(pageable!) +
        SortFilterHelper.constructSortFilters(sortFilters!)
    )
  },

  getClient(clientId: number): Promise<IClientCreateOrUpdate> {
    return api.get(apiRoutes.getClient(clientId))
  },

  createClient(data: IClientCreateOrUpdate): Promise<IClientCreateOrUpdate> {
    return api.post(apiRoutes.createClient, data)
  },

  updateClient(data: IClientCreateOrUpdate): Promise<IClientCreateOrUpdate> {
    return api.put(apiRoutes.createClient, data)
  },

  deleteClient(clientId: number): Promise<any> {
    return api.delete(apiRoutes.deleteClient(clientId))
  },

  searchClients(searchValue: string): Promise<any> {
    return api.get(apiRoutes.searchClients(searchValue))
  },
}
