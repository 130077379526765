import React from 'react'
import 'App.css'
import { LocalStorageKeyEnum } from 'localStorage.enum'
import PrivateRoutes from 'PrivateRoutes'
import Landing from 'components/public/landing/Layout'
import SnackbarAlert from 'components/ui/snackbar/SnackbarAlert'
import { SnackbarProvider } from 'components/ui/snackbar/context/Snackbar'
import { HashRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { isAuthenticated } from 'components/utils/AuthFunctions'
import PrivateRoute from 'components/routing/PrivateRoute'

function App() {
  return (
    <HashRouter basename="/">
      <SnackbarProvider>
        <SnackbarAlert />
        <PrivateRoutes />
      </SnackbarProvider>
    </HashRouter>
  )
}

export default App
