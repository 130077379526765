export const ProjectHeader = [
  { key: 'projectName', value: 'Project Name' },
  { key: 'clientName', value: 'Client' },
  { key: 'teamLeaderName', value: 'Project Manager' },
  { key: 'workingHours', value: 'Spent hours' },
  { key: 'chargeableHours', value: 'Chargeable hours' },
]

export const ClientHeader = [
  { key: 'clientName', value: 'ClientName' },
  { key: 'workingHours', value: 'Spent hours' },
  { key: 'chargeableHours', value: 'Chargeable hours' },
]
