import React from 'react'
import { Col, Row, Table } from 'reactstrap'
import { ITimetrackInterface, TimeTrackCreate } from 'Types/TimeTrack/ITimeTrack'
import styles from 'components/private/home/timetracks/timetrack.module.scss'
import editIcon from 'assets/img/edit-icon.svg'
import deleteIcon from 'assets/img/delete-icon.svg'
import moment from 'moment'

interface IProps {
  timetracks: ITimetrackInterface[]
  loading: boolean
  setDeleteModal: (open: boolean) => void
  setDeleteData: (id: ITimetrackInterface) => void
  setTimeTrack: (track: TimeTrackCreate) => void
  setCreateModal: (open: boolean) => void
}

const Track: React.FC<IProps> = (props: IProps) => {
  function convertTimeTrack(timeTrack: ITimetrackInterface) {
    const track = new TimeTrackCreate()
    track.id = timeTrack.id
    track.chargeableHours = timeTrack.chargeableHours
    track.startDate = timeTrack.startDate
    track.description = timeTrack.description
    track.projectId = timeTrack.project.id
    track.projectRole = timeTrack.projectRole
    track.workingHours = timeTrack.workingHours

    props.setTimeTrack(track)
  }

  return (
    <div className={styles.trackPadding}>
      <h3 className={styles.date}>{moment(props.timetracks[0].startDate).format('DD.MM.YYYY.')}</h3>

      <Table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            <th>Description</th>
            <th>Project</th>
            <th>Spent hours</th>
            <th>Chargeable hours</th>
          </tr>
        </thead>
        <tbody>
          {props.timetracks.map((track, i) => {
            return (
              <tr key={i}>
                <td className={styles.descriptionCol}>{track.description}</td>
                <td className={styles.projectCol}>{track.project.projectName}</td>
                <td>{track.workingHours}</td>
                <td>
                  <Row className={styles.row}>
                    <Col xl="8" md="6" xs="8">
                      {' '}
                      {track.chargeableHours}
                    </Col>
                    <Col xs="2">
                      <img
                        className={styles.iconHover}
                        src={editIcon}
                        alt="Edit icon"
                        onClick={() => {
                          props.setCreateModal(true)
                          convertTimeTrack(track)
                        }}
                      />
                    </Col>
                    <Col xs="2">
                      <img
                        className={styles.iconHover}
                        src={deleteIcon}
                        alt="Edit icon"
                        onClick={() => {
                          props.setDeleteModal(true)
                          props.setDeleteData(track)
                        }}
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default Track
