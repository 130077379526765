import React, { useEffect, useState } from 'react'
import { ReportTimeTrack } from 'Types/Reports/ReportTimeTrack'
import styles from './styles.module.scss'

interface IProps {
  title: string
  oldVacationDays: boolean
  vacationDays: Number
}

const Vacation: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={styles.newVacationContainer}>
      <div className={styles.header}>
        <span className={styles.title}>{props.title}:</span>
        {<span className={styles.vacationDays}>{props.vacationDays.toString()}</span>}
      </div>
    </div>
  )
}
export default Vacation
