import { UserVacationRequest } from 'components/private/home/absences/UserVacationRequest'
import { apiRoutes } from 'const/apiRoutes'
import { IUser } from 'Types/IUser'
import { api } from './api'

export default {
  getUsers(): Promise<IUser[]> {
    return api.get(apiRoutes.getUsers)
  },

  getEmployees(): Promise<IUser[]> {
    return api.get(apiRoutes.getEmployees)
  },

  makeNewEmployeeSupervisorPair(employeeId: number, supervisorId: number): Promise<any> {
    return api.put(apiRoutes.makeNewEmployeeSupervisorPair(employeeId, supervisorId))
  },

  getUserVacations(userId: number): Promise<any> {
    return api.get(apiRoutes.getUserVacations(userId))
  },

  updateUserVacationDays(userId: number, vacationDays: UserVacationRequest): Promise<any> {
    return api.post<any>(apiRoutes.updateUserVacationDays(userId), vacationDays)
  },
}
