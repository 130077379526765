import { apiRoutes } from 'const/apiRoutes'
import {
  Absence,
  AbsenceReason,
  AbsenceRequest,
  ISickLeave,
  IUserAbsence,
} from 'Types/Absences/Absence'
import { api } from './api'

export default {
  getAbsences(): Promise<Absence[]> {
    return api.get(apiRoutes.getAbsences)
  },

  getAbsence(absenceId: number): Promise<Absence> {
    return api.get(apiRoutes.getAbsence(absenceId))
  },

  getAbsencesForUser(userId: string): Promise<Absence[]> {
    return api.get(apiRoutes.getAbsencesForUser(userId))
  },

  getAbsencesReasonForProject(
    projectId: string,
    startDate: string,
    endDate: string,
    absenceReason: AbsenceReason
  ): Promise<ISickLeave[]> {
    return api.get(
      apiRoutes.getAbsencesReasonForProject(projectId, startDate, endDate, absenceReason)
    )
  },
  getAbsencesForProject(
    projectId: string,
    startDate: string,
    endDate: string
  ): Promise<IUserAbsence> {
    return api.get(apiRoutes.getAbsencesForProject(projectId, startDate, endDate))
  },
  getSumAbsencesForProject(
    projectId: string,
    startDate: string,
    endDate: string
  ): Promise<{ numberOfVacationDays: number }> {
    return api.get(apiRoutes.getSumAbsencesForProject(projectId, startDate, endDate))
  },

  getAbsencesForClient(clientId: string): Promise<Absence[]> {
    return api.get(apiRoutes.getAbsencesForClient(clientId))
  },

  createAbsence(data: AbsenceRequest): Promise<Absence> {
    return api.post(apiRoutes.createAbsence, data)
  },

  updateAbsence(absenceId: number, data: AbsenceRequest): Promise<Absence> {
    return api.put(apiRoutes.updateAbsence(absenceId), data)
  },

  deleteAbsence(absenceId: number): Promise<any> {
    return api.delete(apiRoutes.deleteAbsence(absenceId))
  },
}
