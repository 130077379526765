import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import clientApi from 'services/clientApi'
import userApi from 'services/userApi'
import { IProjectCreate } from 'Types/Project/IProjectCreate'
import { IProjectGet } from 'Types/Project/IProjectGet'
import * as Yup from 'yup'
import Select from 'react-select'
import { ILabel } from 'Types/ILabel'
import { setLabel } from 'components/utils/SelectFunctions'

interface IProps {
  isOpen: boolean
  setCreateModal: (open: boolean) => void
  createProject: (project: IProjectCreate) => void
  update: boolean
  projectData?: IProjectGet
  setUpdate: (update: boolean) => void
  setUpdateData: (update: any) => void
  updateProject: (projectId: number, update: IProjectCreate) => void
}

const ProjectCreateModal: React.FC<IProps> = (props: IProps) => {
  const [clientData, setClientData] = useState<Array<ILabel>>()
  const [userData, setUserData] = useState<Array<ILabel>>()
  const [projectTeamIds, setProjectTeamIds] = useState<ILabel[]>([])
  const [clientId, setclientId] = useState<ILabel>()
  const [teamleaderId, setteamleaderId] = useState<ILabel>()

  function initModal() {
    Promise.all([clientApi.getClients(), userApi.getUsers()]).then((values) => {
      values[0] && setClientData(setLabel(values[0], 'clientName'))
      values[1] && setUserData(setLabel(values[1], 'fullName'))
      setProjectData()
    })
  }

  function setProjectData() {
    //set states if recevied data matches arrays data
    props.projectData
      ? setProjectTeamIds(setLabel(props.projectData?.projectTeam!, 'fullName'))
      : setProjectTeamIds([])
    props.projectData &&
      setteamleaderId({
        value: props.projectData.teamLeader.id,
        label: props.projectData.teamLeader.fullName,
      })
    props.projectData &&
      setclientId({
        value: props.projectData?.client.id,
        label: props.projectData?.client.clientName,
      })
  }

  //manually setting field value for Formik while using Select
  function handlePTChange(e: any, setFieldValue: any, fieldName: string, props2?: any): void {
    if (e) {
      e.map((ev: any) => setFieldValue(fieldName, [...props2.values.projectTeamIds, ev.value]))
      setProjectTeamIds(e)
    } else {
      setFieldValue('projectTeamIds', [])
      setProjectTeamIds([])
    }
  }

  //manually setting field value for Formik while using Select
  function handleTLChange(e: any, setFieldValue: any, fieldName: string): void {
    if (e) {
      setFieldValue(fieldName, e.value)
      fieldName === 'clientId' ? setclientId(e) : setteamleaderId(e)
    } else {
      setFieldValue(fieldName, [])
      fieldName === 'clientId'
        ? setclientId({ label: '', value: 0 })
        : setteamleaderId({ label: '', value: 0 })
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onOpened={() => initModal()}
      centered={true}
      contentClassName="modalContent"
      size="md"
    >
      <ModalBody>
        <Container>
          <h3 className="modalTitle">{props.update ? 'Update project' : 'New project'}</h3>
          <Formik
            enableReinitialize
            initialValues={{
              clientId: props.projectData ? props.projectData.client.id : null,
              projectName: props.projectData ? props.projectData.projectName : '',
              projectTeamIds: props.projectData
                ? props.projectData?.projectTeam.map((person) => person.id)!
                : [],
              teamLeaderId: props.projectData ? props.projectData.teamLeader.id : null,
            }}
            onSubmit={(values: IProjectCreate) => {
              if (props.update) {
                props.updateProject(props.projectData?.id!, values)
                props.setCreateModal(false)
                props.setUpdateData(null)
                props.setUpdate(false)
              } else {
                props.createProject(values)
                props.setCreateModal(false)
              }
            }}
            validationSchema={Yup.object().shape({
              clientId: Yup.string().required('Client is required').nullable(),
              projectName: Yup.string().required('Project name is required'),
              projectTeamIds: Yup.array().required('Team is required').nullable(true),
              teamLeaderId: Yup.string().required('Team leader is required').nullable(),
            })}
          >
            {(props2) => {
              const { values, touched, errors, handleChange, handleBlur, setFieldValue } = props2
              return (
                <Form>
                  <FormGroup>
                    <Label className="modalLabel" for="projectName">
                      Project Name
                    </Label>
                    <Input
                      className="modalInput"
                      type="text"
                      value={values.projectName}
                      placeholder="Project name"
                      name="projectName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.projectName && touched.projectName && (
                      <div className="input-feedback">{errors.projectName}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="modalLabel" for="Client">
                      Client
                    </Label>
                    <Select
                      value={clientId}
                      placeholder="Select client..."
                      options={clientData}
                      name="clientId"
                      onBlur={handleBlur}
                      onChange={(e) => handleTLChange(e, setFieldValue, 'clientId')}
                      type="text"
                    />
                    {errors.clientId && touched.clientId && (
                      <div className="input-feedback">{errors.clientId}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="modalLabel" for="Project manager">
                      Project Manager
                    </Label>
                    <Select
                      value={teamleaderId}
                      placeholder="Select project manager..."
                      options={userData}
                      name="teamLeaderId"
                      onBlur={handleBlur}
                      onChange={(e) => handleTLChange(e, setFieldValue, 'teamLeaderId')}
                      type="text"
                    />
                    {errors.teamLeaderId && touched.teamLeaderId && (
                      <div className="input-feedback">{errors.teamLeaderId}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="modalLabel" for="Project team">
                      Project Team
                    </Label>
                    <Select
                      // defaultValue={userData.map((user) => user.id === values.projectTeamIds[0])}
                      value={projectTeamIds}
                      placeholder="Select project team..."
                      options={userData}
                      isMulti={true}
                      name="projectTeamIds"
                      onBlur={handleBlur}
                      onChange={(e) => handlePTChange(e, setFieldValue, 'projectTeamIds', props2)}
                      type="text"
                    />
                    {errors.projectTeamIds && touched.projectTeamIds && (
                      <div className="input-feedback">{errors.projectTeamIds}</div>
                    )}
                  </FormGroup>
                  <Row className="modalRowCenter">
                    <button
                      className="cancelButton modalButtonMargin"
                      type="button"
                      onClick={() => {
                        props.setCreateModal(false)
                        if (props.update) {
                          props.setUpdate(false)
                          props.setUpdateData(null)
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button className="modalAddButton modalButtonMargin" type="submit">
                      {props.update ? 'Update' : 'Add'}
                    </button>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default ProjectCreateModal
