import React, { useEffect, useState } from 'react'
import { Table, Row, Col } from 'reactstrap'
import styles from 'components/ui/sortableTable.module.scss'
import editIcon from 'assets/img/edit-icon.svg'
import deleteIcon from 'assets/img/delete-icon.svg'
import arrowIcon from 'assets/img/arrow-icon.svg'
import Skeleton from 'react-loading-skeleton'
import { SortFilter } from 'Types/SortFilter'
import Pagination from 'Types/Pagination'
import Paginate from './Paginate'
import 'components/ui/pagination.css'

interface IProps {
  headers: any[]
  data: any[]
  paginateData?: Pagination
  loading?: boolean
  setDeleteModal: (open: boolean) => void
  setDeleteData: (data: any) => void
  setUpdateData: (update: boolean) => void
  setUpdateModal: (open: boolean) => void
  getDataForRow: (id: number) => void
  setSortFilters: (filter: SortFilter[]) => void
  getAllData: () => void
}

const SortableTable: React.FC<IProps> = (props: IProps) => {
  const [sortFilters, setSortFilters] = useState<SortFilter[]>([])

  useEffect(() => {
    props.setSortFilters(sortFilters)
  }, [sortFilters])

  async function getFilteredData(key: any) {
    const index = sortFilters.findIndex((obj) => obj.key === key)
    if (index !== -1) {
      const oldFilter = sortFilters.find((_, i) => i === index)
      const newFilters = sortFilters.filter((_, i) => i !== index)
      setSortFilters(newFilters)

      if (oldFilter?.value === 'asc') {
        setSortFilters((oldArray) => [...oldArray, new SortFilter(key, 'desc')])
      } else {
        setSortFilters((oldArray) => [...oldArray, new SortFilter(key, 'asc')])
      }
    } else {
      setSortFilters((oldArray) => [...oldArray, new SortFilter(key, 'asc')])
    }
  }

  function rotatedUp(key: any) {
    const index = sortFilters.findIndex((obj) => obj.key === key)
    if (index !== -1) {
      const oldFilter = sortFilters.find((_, i) => i === index)
      if (oldFilter?.value === 'asc') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <>
      <Table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            {props.headers.map((header, i) => {
              //TODO sort arrow
              return (
                <th key={i}>
                  {rotatedUp(header.key) ? (
                    <img
                      className={styles.iconHover + ' ' + styles.iconRotate}
                      src={arrowIcon}
                      alt="Arrow icon"
                      onClick={() => getFilteredData(header.key)}
                    />
                  ) : (
                    <img
                      className={styles.iconHover}
                      src={arrowIcon}
                      alt="Arrow icon"
                      onClick={() => getFilteredData(header.key)}
                    />
                  )}

                  <span>{header.value}</span>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {props.data.length === 0 ? (
            <>
              {props.loading ? (
                <>
                  {props.headers.map((value, i) => {
                    return (
                      <tr key={i}>
                        {props.headers.map((value, i) => {
                          return (
                            <td key={i}>
                              <Skeleton />
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </>
              ) : (
                <tr className={styles.noData}>
                  <td className={styles.noDataText} colSpan={props.headers.length}>
                    No data
                  </td>
                </tr>
              )}
            </>
          ) : (
            <>
              {props.data.map((data, i) => {
                return (
                  <tr key={i}>
                    {props.headers.map((header, i) => {
                      if (props.headers.length - 1 === i) {
                        return (
                          <td key={i}>
                            <Row className={styles.row}>
                              <Col xl="8" md="6" xs="8">
                                {data[header.key]}
                              </Col>
                              <Col xs="2">
                                <img
                                  className={styles.iconHover}
                                  src={editIcon}
                                  alt="Edit icon"
                                  onClick={() => {
                                    props.setUpdateData(true)
                                    props.setUpdateModal(true)
                                    props.getDataForRow(data.id)
                                  }}
                                />
                              </Col>
                              <Col xs="2">
                                <img
                                  className={styles.iconHover}
                                  src={deleteIcon}
                                  alt="Delete icon"
                                  onClick={() => {
                                    props.setDeleteModal(true)
                                    props.setDeleteData(data)
                                  }}
                                />
                              </Col>
                            </Row>
                          </td>
                        )
                      }
                      return <td key={i}>{data[header.key]}</td>
                    })}
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </Table>
      <Paginate
        totalPages={props.paginateData?.totalPages!}
        pageable={props.paginateData?.pageable!}
        paginateData={props.getAllData}
      />
    </>
  )
}

export default SortableTable
