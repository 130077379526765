import React, { useContext, useEffect, useState } from 'react'
import { Container, NavLink, Row, Table } from 'reactstrap'
import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'
import styles from 'components/private/home/timetracks/timetrack.module.scss'
import addIcon from 'assets/img/add-icon.svg'
import { SupervisorSubordinates } from './SupervisorSubordinates'
import supervisorApi from 'services/supervisorApi'
import SupervisorCreateModal from './SupervisorCreateModal'
import userApi from 'services/userApi'
import { IUser } from 'Types/IUser'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import { LocalStorageKeyEnum } from 'localStorage.enum'

const Supervisor: React.FC = () => {
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [supervisorSubordinates, setSupervisorSubordinates] = useState<SupervisorSubordinates[]>([])
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [employees, setEmployees] = useState<IUser[]>([])
  const snackbarContext = useContext(SnackbarContext)
  const [userId, setUserId] = useState<string>()

  useEffect(() => {
    getSupervisorSubordinates()
    setIsAdmin(localStorage.getItem('IS_ADMIN') === 'true' ? true : false)
    const userId = localStorage.getItem(LocalStorageKeyEnum.USERID as string)
    if (userId) setUserId(userId)
    getAllEmployees()
  }, [])

  function getAllEmployees() {
    try {
      userApi.getEmployees().then((response) => {
        setEmployees(response)
      })
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  function getSupervisorSubordinates() {
    supervisorApi.getSupervisorSubordinates().then((response) => {
      setSupervisorSubordinates(response)
    })
  }

  async function makeNewEmployeeSupervisorPair(userId: number) {
    try {
      await userApi.makeNewEmployeeSupervisorPair(userId, Number(localStorage.getItem('USERID')))
      await getSupervisorSubordinates()
      snackbarContext?.setShowSnackbarData(true, 'Supervisor added.', 'success')
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  return (
    <Container>
      {/* generic content page sets same header layout and renders different content as React FC */}
      <GenericContentPage
        header={
          isAdmin
            ? {
                title: 'Supervisor & Employee',
                buttonFunc: setCreateModal,
                buttonTitle: 'Supervise Employee',
                icon: addIcon,
              }
            : { title: 'Supervisor & Employee' }
        }
      >
        <>
          <Table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                <th style={{ textAlign: 'center', fontSize: 'larger' }}>Employee</th>
                <th style={{ textAlign: 'center', fontSize: 'larger' }}>Supervisors</th>
              </tr>
            </thead>
            <tbody>
              {supervisorSubordinates.map((supervisorSubordinates, i) => {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: 'center', fontSize: '14px' }}>
                      <NavLink
                        to={{
                          pathname: '/meeting',
                          state: { userId: supervisorSubordinates.subordinate?.id },
                        }}
                        className="remove-underline"
                        style={
                          !isAdmin
                            ? Number(userId) != supervisorSubordinates.subordinate?.id
                              ? { pointerEvents: 'none' }
                              : { pointerEvents: 'auto' }
                            : { pointerEvents: 'auto' }
                        }
                      >
                        <button
                          className="addButton"
                          disabled={
                            !isAdmin && Number(userId) != supervisorSubordinates.subordinate?.id
                          }
                        >
                          {supervisorSubordinates.subordinate?.fullName}
                        </button>
                      </NavLink>
                    </td>

                    <td style={{ textAlign: 'center', fontSize: '14px' }}>
                      {supervisorSubordinates.supervisors?.length
                        ? supervisorSubordinates.supervisors.map((supervisor: IUser) => {
                            return (
                              <div key={supervisor.id}>
                                <Row style={{ display: 'inline-grid' }}>{supervisor.fullName}</Row>
                              </div>
                            )
                          })
                        : '-'}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <SupervisorCreateModal
            isOpen={createModal}
            setCreateModal={setCreateModal}
            employeeData={employees}
            makeNewEmployeeSupervisorPair={makeNewEmployeeSupervisorPair}
          />
        </>
      </GenericContentPage>
    </Container>
  )
}

export default Supervisor
