import { Form, Formik, Field } from 'formik'
import React from 'react'
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { IUser } from 'Types/IUser'

interface IProps {
  isOpen: boolean
  setCreateModal: (open: boolean) => void
  makeNewEmployeeSupervisorPair: (userId: number) => void
  employeeData: IUser[]
}

const SupervisorCreateModal: React.FC<IProps> = (props: IProps) => {
  function printUser(userId: number) {
    // make new (employee, supervisor) pair
    props.makeNewEmployeeSupervisorPair(userId)
    props.setCreateModal(false)
  }

  return (
    <Modal isOpen={props.isOpen} centered={true} contentClassName="modalContent" size="md">
      <ModalBody>
        <Container>
          <h3 className="modalTitle">Choose employee you want to supervise</h3>
          <Formik
            enableReinitialize
            initialValues={props.employeeData.map((employee, i) => {
              return (
                <tr key={i}>
                  <td>{employee.id}</td>
                  <td>{employee.fullName}</td>
                </tr>
              )
            })}
            onSubmit={(values) => {}}
          >
            <Form>
              <Col sm={12} md={6}>
                <FormGroup>
                  {props.employeeData.map((employee, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <button
                            className="modalAddButton modalButtonMargin"
                            onClick={() => printUser(employee.id)}
                            type="button"
                          >
                            {employee.fullName}
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </FormGroup>
              </Col>
              <Row className="modalRowCenter">
                <button
                  className="cancelButton modalButtonMargin"
                  type="button"
                  onClick={() => {
                    props.setCreateModal(false)
                  }}
                >
                  Cancel
                </button>
              </Row>
            </Form>
          </Formik>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default SupervisorCreateModal
