import React, { FunctionComponent } from 'react'
import styles from 'components/ui/sidebar/action/action.module.scss'
import sidebarStyles from 'components/layout/routes.module.scss'
import { NavLink } from 'react-router-dom'

interface IProps {
  action: {
    icon: FunctionComponent<React.SVGProps<SVGSVGElement>>
    text: string
    path: string
  }
}

export const Action: React.FC<IProps> = (props: IProps) => {
  function collapseSidebar() {
    document.getElementById('sidebar')?.classList.add(`${sidebarStyles.collapsed}`)
  }

  return (
    <div className={styles.container}>
      <NavLink to={props.action.path} className={styles.notActive}>
        {/* <NavLink to={props.action.path} className={styles.notActive} activeClassName={props.action.text !== 'Sign Out' ? styles.active : ''} onClick={() => collapseSidebar()}> */}
        <div className={styles.actionIcon}>
          <props.action.icon></props.action.icon>
        </div>
        <span className={styles.displayActionText}>{props.action.text}</span>
      </NavLink>
    </div>
  )
}
