import React, { useEffect, useState, useContext } from 'react'
import { ReportTimeTrack } from 'Types/Reports/ReportTimeTrack'
import styles from 'components/private/home/timetracks/timetrack.module.scss'
import reportStyles from './styles.module.scss'
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap'
import clientApi from 'services/clientApi'
import projectApi from 'services/projectApi'
import reportApi from 'services/reportsApi'
import userApi from 'services/userApi'
import { Filter } from 'Types/Filter'
import Select from 'react-select'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ILabel } from 'Types/ILabel'
import { setLabel } from 'components/utils/SelectFunctions'
import exportPdf from 'assets/img/damir.png'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'

interface IProps {
  getTimeTrackReports: () => void
  timeTrackReports: ReportTimeTrack[]
  setFilters: (data: any) => void
  filters: Filter[]
}

const ReportsTimeTrack: React.FC<IProps> = (props: IProps) => {
  const snackbarContext = useContext(SnackbarContext)
  const [clientsArray, setClientsArray] = useState<ILabel[]>()
  const [projectsArray, setProjectsArray] = useState<ILabel[]>()
  const [usersArray, setUsersArray] = useState<ILabel[]>()
  const [loading, setLoading] = useState<boolean>(false)

  let client: ILabel
  let project: ILabel
  let employee: ILabel

  useEffect(() => {
    getEmployees()
    getClients()
    getProjects()
  }, [])

  function getClients() {
    clientApi.getClients().then((response) => {
      setClientsArray(setLabel(response, 'clientName'))
    })
  }

  function getEmployees() {
    userApi.getUsers().then((response) => {
      setUsersArray(setLabel(response, 'fullName'))
    })
  }
  function getProjects() {
    projectApi.getProjects().then((response) => {
      setProjectsArray(setLabel(response.content, 'projectName'))
    })
  }

  function pdfExport() {
    setLoading(true)
    let projectId = props.filters.find((el) => el.key === 'projectId')?.value
    let startDate = props.filters.find((el) => el.key === 'startDate')?.value
    let endDate = props.filters.find((el) => el.key === 'endDate')?.value

    if (projectId) {
      reportApi.exportWorkingHours(projectId!, startDate!, endDate!).then((response: any) => {
        const linkSource = `data:application/pdf;base64,${response.base64}`
        const downloadLink = document.createElement('a')
        let client = projectsArray?.find((el) => el.value === projectId!)?.label
        const fileName = client + ' - brainit - ' + startDate + ' - ' + endDate + '.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        setLoading(false)
      })
    } else {
      snackbarContext?.setShowSnackbarData(true, 'Please Select Project!', 'danger')
      setLoading(false)
    }
  }

  async function getFilteredReports(e: ILabel, caseName: string) {
    setLoading(true)
    const index = props.filters.findIndex((obj) => obj.key === caseName)
    if (index !== -1) {
      const newFilters = props.filters.filter((_, i) => i !== index)
      props.setFilters(newFilters)
    }
    switch (caseName) {
      case 'userId':
      case 'clientId':
      case 'projectId':
        {
          if (caseName && e) {
            props.setFilters((oldArray: Filter[]) => [...oldArray, new Filter(caseName, e.value)])
          }
        }
        break
    }
    setLoading(false)
  }

  const ReportsTimeTrackSchema = Yup.object().shape({
    userFullName: Yup.string().required("User's name is required"),
    clientName: Yup.string().required('Client name is required'),
    projectName: Yup.string().required('Project name is required'),
    workingHours: Yup.number().required('Working hours are required').nullable(),
    chargeableHours: Yup.number().required('Chargable hours are required').nullable(),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={new ReportTimeTrack()}
      onSubmit={async (values: any) => {
        console.log(values)
      }}
      validationSchema={ReportsTimeTrackSchema}
    >
      {(props2) => {
        const { handleBlur } = props2
        return (
          <Form>
            <Row className="tableHeaderRow">
              <Col sm={12} md={3}>
                <FormGroup>
                  <Label className="modalLabel" for="clientName">
                    Client
                  </Label>
                  <Select
                    isClearable
                    options={clientsArray}
                    value={client}
                    onChange={(e: any) => getFilteredReports(e, 'clientId')}
                    onBlur={handleBlur}
                    placeholder="Select client"
                    name="clientName"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={3}>
                <FormGroup>
                  <Label className="modalLabel" for="projectName">
                    Project
                  </Label>
                  <Select
                    isClearable
                    options={projectsArray}
                    value={project}
                    onChange={(e: any) => getFilteredReports(e, 'projectId')}
                    onBlur={handleBlur}
                    placeholder="Select project"
                    name="projectName"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={3}>
                <FormGroup>
                  <Label className="modalLabel" for="userFullName">
                    Employee
                  </Label>
                  <Select
                    isClearable
                    options={usersArray}
                    value={employee}
                    onChange={(e: any) => getFilteredReports(e, 'userId')}
                    onBlur={handleBlur}
                    placeholder="Select employee"
                    name="userFullName"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={3}>
                <FormGroup>
                  <Label className="modalLabel" for="userFullName">
                    Working Hours
                  </Label>
                  <button
                    className={reportStyles.exportButton}
                    type="button"
                    onClick={() => {
                      pdfExport()
                    }}
                  >
                    <img className="addButtonIcon" src={exportPdf} alt="Add icon" />
                    {!loading ? 'Export PDF' : 'Loading...'}
                  </button>
                </FormGroup>
              </Col>
            </Row>
            <Table className={styles.table}>
              <thead className={styles.thead}>
                <tr>
                  <th>Employee</th>
                  <th>Client</th>
                  <th>Project</th>
                  <th>Spent hours</th>
                  <th>Chargeable hours</th>
                </tr>
              </thead>
              <tbody>
                {props.timeTrackReports.map((report, i) => {
                  return (
                    <tr key={i}>
                      <td>{report.userFullName}</td>
                      <td>{report.clientName}</td>
                      <td>{report.projectName}</td>
                      <td>{report.workingHours}</td>
                      <td>{report.chargeableHours}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ReportsTimeTrack
