import Layout from 'components/layout/Layout'
import AbsencesLayout from 'components/private/home/absences/AbsencesLayout'
import Clients from 'components/private/home/clients/Clients'
import Projects from 'components/private/home/projects/Projects'
import ReportsLayout from 'components/private/home/reports/ReportsLayout'
import TimeTracker from 'components/private/home/timetracks/TimeTracker'
import Meeting from 'components/private/meeting/Meeting'
import Supervisor from 'components/private/supervisor/Supervisor'
import TimetrackDetails from 'components/private/timetrackDetails/TimetrackDetails'
import NotFound from 'components/public/landing/404/NotFound'
import Landing from 'components/public/landing/Layout'
import PrivateRoute from 'components/routing/PrivateRoute'
import SnackbarAlert from 'components/ui/snackbar/SnackbarAlert'
import { SnackbarProvider } from 'components/ui/snackbar/context/Snackbar'
import { LocalStorageKeyEnum } from 'localStorage.enum'
import React from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<Landing />} path="/" />
      {/* <PrivateRoute element={Home} path="/home" /> */}
      {/* <PrivateRoute element={Dashboard} path="/dashboard" /> */}
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <Projects />
            </Layout>
          </PrivateRoute>
        }
        path="/projects"
      />
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <Clients />
            </Layout>
          </PrivateRoute>
        }
        path="/clients"
      />
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <TimeTracker />
            </Layout>
          </PrivateRoute>
        }
        path="/timetracker"
      />
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <TimetrackDetails />
            </Layout>
          </PrivateRoute>
        }
        path="/timetracker-details"
      />
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <AbsencesLayout />
            </Layout>
          </PrivateRoute>
        }
        path="/timeline"
      />
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <ReportsLayout />
            </Layout>
          </PrivateRoute>
        }
        path="/reports"
      />
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <Supervisor />
            </Layout>
          </PrivateRoute>
        }
        path="/supervisor"
      />
      <Route
        element={
          <PrivateRoute>
            <Layout>
              <Meeting />
            </Layout>
          </PrivateRoute>
        }
        path="/meeting"
      />
      {/* Private web page end */}

      <Route path="/404" element={<NotFound />} />
      <Route path="/*" element={<Navigate to="/404" replace />} />
    </Routes>
  )
}

export default PrivateRoutes
