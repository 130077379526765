import { normalizeDate } from 'Helpers/date'
import moment from 'moment'
import { IMonth } from 'Types/TimeTrack/IMonth'

export function getAllMonths(year: number): IMonth[] {
  let months: IMonth[] = []
  let monthsName = moment.months()
  monthsName.forEach((month, i) => {
    months.push({
      name: month,
      start: normalizeDate(
        moment(`${year}-${i + 1}-1}`, 'YYYY-MM-DD')
          .startOf('month')
          .toISOString()
      ),
      end: normalizeDate(
        moment(`${year}-${i + 1}-1}`, 'YYYY-MM-DD')
          .endOf('month')
          .toISOString()
      ),
    })
  })
  return months
}
