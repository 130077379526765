import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { ReportTimeTrack } from 'Types/Reports/ReportTimeTrack'
import { Form, FormGroup, Input, Label, Row } from 'reactstrap'
import Select from 'react-select'
import { ILabel } from 'Types/ILabel'
import { setLabel } from 'components/utils/SelectFunctions'
import projectApi from 'services/projectApi'
import * as Yup from 'yup'
import moment, { Moment } from 'moment'
import { Filter } from 'Types/Filter'

interface IProps {
  setFilters: (data: any) => void
  filters: Filter[]
  setLoading: (loading: boolean) => void
}

const Filters: React.FC<IProps> = (props: IProps) => {
  const [projectsArray, setProjectsArray] = useState<ILabel[]>()

  let project: ILabel
  let month: ILabel

  useEffect(() => {
    getProjects()
  }, [])

  function getProjects() {
    projectApi.getProjects().then((response) => {
      setProjectsArray(setLabel(response.content, 'projectName'))
    })
  }

  function getFilteredReports(e: ILabel, caseName: string) {
    props.setLoading(true)

    // Remove any existing filters for the caseName
    const newFilters = props.filters.filter((filter) => {
      if (caseName == 'month') {
        return filter.key !== 'startDate' && filter.key !== 'endDate'
      } else {
        return filter.key !== caseName
      }
    })

    // Handle specific cases for 'month' and 'projectId'
    switch (caseName) {
      case 'month': {
        if (e) {
          // Add startDate and endDate based on the selected month
          newFilters.push(
            new Filter(
              'startDate',
              moment(e as string)
                .startOf('month')
                .format('YYYY-MM-DD'),
              'Start date'
            ),
            new Filter(
              'endDate',
              moment(e as string)
                .endOf('month')
                .format('YYYY-MM-DD'),
              'End date'
            )
          )
        }
        break
      }
      case 'projectId': {
        if (e) {
          // Add projectId filter
          newFilters.push(new Filter(caseName, e.value, e.label))
        }
        break
      }
      default:
        break
    }

    // Update the filters without duplicates
    props.setFilters(newFilters)
    props.setLoading(false)
  }

  const ReportsTimeTrackSchema = Yup.object().shape({
    userFullName: Yup.string().required("User's name is required"),
    clientName: Yup.string().required('Client name is required'),
    projectName: Yup.string().required('Project name is required'),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={new ReportTimeTrack()}
      onSubmit={async (values: any) => {
        console.log(values)
      }}
      validationSchema={ReportsTimeTrackSchema}
    >
      {(props2) => {
        const { handleBlur } = props2
        return (
          <Form>
            <Row className="tableHeaderRow">
              <Row>
                <FormGroup>
                  <Label className="modalLabel" for="projectName">
                    Project
                  </Label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 100 }),
                    }}
                    isClearable
                    options={projectsArray}
                    value={project}
                    onChange={(e: any) => {
                      getFilteredReports(e, 'projectId')
                    }}
                    onBlur={handleBlur}
                    placeholder="Select project"
                    name="projectName"
                    type="text"
                    defaultMenuIsOpen
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup>
                  <Label className="modalLabel" for="month">
                    Month
                  </Label>
                  <Input
                    id="month"
                    name="month"
                    placeholder="month placeholder"
                    type="month"
                    value={moment(
                      props.filters.find((filter) => filter.key === 'startDate')?.value
                    ).format('YYYY-MM')}
                    onChange={(e: any) => {
                      getFilteredReports(e.target.value, 'month')
                    }}
                  />
                </FormGroup>
              </Row>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
export default Filters
