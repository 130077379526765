import { apiRoutes } from 'const/apiRoutes'
import { ITimetrack, ITimetrackGet, TimeTrackCreate } from 'Types/TimeTrack/ITimeTrack'
import { api } from './api'

export default {
  getAllTimeTrackForUser(
    userId: string,
    startDate: string,
    endDate: string
  ): Promise<ITimetrackGet> {
    return api.get(apiRoutes.getAllTimeTrackForUser(userId, startDate, endDate))
  },
  createTimeTrack(timeTrack: TimeTrackCreate): Promise<ITimetrack> {
    return api.post(apiRoutes.createTimeTrack, timeTrack)
  },
  updateTimeTrack(timeTrack: TimeTrackCreate, timeTrackId: number): Promise<ITimetrack> {
    return api.put(apiRoutes.updateTimeTrack(timeTrackId), timeTrack)
  },
  deleteTimeTrack(timeTrackId: number): Promise<any> {
    return api.delete(apiRoutes.deleteTimeTrack(timeTrackId))
  },
  getUserTimePeriod(userId: string, endDate: string, startDate: string): Promise<any> {
    return api.get(apiRoutes.getUserTimePeriod(userId, endDate, startDate))
  },
  getUserMissingTimetracks(userId: string, endDate: string, startDate: string): Promise<any> {
    return api.get(apiRoutes.getUserTimePeriod(userId, endDate, startDate))
  },
  getProjectMissingTimetracks(projectId: string, startDate: string, endDate: string): Promise<any> {
    return api.get(apiRoutes.getProjectMissingTimetracks(projectId, startDate, endDate))
  },
}
