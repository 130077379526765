import _ from 'lodash'
import { IProjectMissingTimetrack, ISickLeave } from 'Types/Absences/Absence'

export const showUserMissingTimetracks = (
  userId: number,
  projectMissingTimetracks: IProjectMissingTimetrack[] | undefined
): string[][] | undefined => {
  const userMissingTimetracks = projectMissingTimetracks?.find(
    (projectTimetracks) => projectTimetracks.user.id === userId
  )?.missingTimeTracks
  return userMissingTimetracks ? groupDates(userMissingTimetracks) : undefined
}
export const showUserSickDays = (
  userId: number,
  sickLeaveDays: ISickLeave[] | null
): string[][] | undefined => {
  const userSickDays = sickLeaveDays
    ?.find((sickLeave) => sickLeave.user.id === userId)
    ?.absences.map((absence) => getDatesInRange(absence.absenceFrom, absence.absenceTo))
  return userSickDays
}

export function getDatesInRange(absenceFrom: string, absenceTo: string) {
  let startDate = new Date(absenceFrom)
  let endDate = new Date(absenceTo)
  let dateArray = []

  while (startDate <= endDate) {
    dateArray.push(startDate.toISOString().split('T')[0]) // Format as YYYY-MM-DD
    startDate.setDate(startDate.getDate() + 1) // Increment the date
  }

  return dateArray
}

export function groupDates(dates: string[]) {
  // Sort the dates and convert to Date objects
  const sortedDates = _.sortBy(dates.map((date) => new Date(date)))

  // Use _.reduce to group consecutive dates
  return _.reduce(
    sortedDates,
    (groups: any, currentDate: any) => {
      const lastGroup: any = _.last(groups)

      if (lastGroup && (currentDate - (_.last(lastGroup) as any)) / (1000 * 60 * 60 * 24) === 1) {
        lastGroup.push(currentDate)
      } else {
        groups.push([currentDate])
      }

      return groups
    },
    []
  ) // Convert back to string
}
