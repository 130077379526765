import { Form, Formik } from 'formik'
import React from 'react'
import { Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { IClientCreateOrUpdate } from 'Types/Client/IClientCreateOrUpdate'
import * as Yup from 'yup'

interface IProps {
  isOpen: boolean
  setCreateModal: (open: boolean) => void
  createClient: (client: IClientCreateOrUpdate) => void
  update: boolean
  clientData?: IClientCreateOrUpdate
  setUpdate: (update: boolean) => void
  setClientData: (client: any) => void
  updateClient: (client: IClientCreateOrUpdate) => void
}

const ClientCreateModal: React.FC<IProps> = (props: IProps) => {
  return (
    <Modal isOpen={props.isOpen} centered={true} contentClassName="modalContent" size="md">
      <ModalBody>
        <Container>
          <h3 className="modalTitle">{props.update ? 'Update client' : 'New client'}</h3>
          <Formik
            enableReinitialize
            initialValues={{
              id: props.clientData ? props.clientData.id : 0,
              clientName: props.clientData ? props.clientData.clientName : '',
            }}
            onSubmit={(values: IClientCreateOrUpdate) => {
              if (props.update) {
                props.updateClient(values)
                props.setCreateModal(false)
                props.setUpdate(false)
                props.setClientData(null)
              } else {
                props.createClient(values)
                props.setCreateModal(false)
              }
            }}
            validationSchema={Yup.object().shape({
              clientName: Yup.string().required('Client name is required'),
            })}
          >
            {(props2) => {
              const { values, touched, errors, handleChange, handleBlur } = props2
              return (
                <Form>
                  <FormGroup>
                    <Label className="modalLabel" for="clientName">
                      Client name
                    </Label>
                    <Input
                      className="modalInput"
                      type="text"
                      value={values.clientName}
                      placeholder="Client name"
                      name="clientName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></Input>
                    {errors.clientName && touched.clientName && (
                      <div className="input-feedback">{errors.clientName}</div>
                    )}
                  </FormGroup>
                  <Row className="modalRowCenter">
                    <Col>
                      <button
                        className="cancelButton modalButtonMargin"
                        type="button"
                        onClick={() => {
                          props.setCreateModal(false)
                          if (props.update) {
                            props.setUpdate(false)
                            props.setClientData(null)
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col>
                      <button className="modalAddButton modalButtonMargin" type="submit">
                        {props.update ? 'Update' : 'Add'}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default ClientCreateModal
