import React, { useState } from 'react'
import { Card, Col, Row } from 'reactstrap'
import { IMeeting } from 'Types/Meeting/Meeting'
import styles from './styles.module.scss'
import arrowIcon from 'assets/img/arrow-icon.svg'
import deleteIcon from 'assets/img/delete-icon.svg'
import moment from 'moment'

interface IProps {
  meeting: IMeeting
  setDeleteModal: (open: boolean) => void
  setDeleteData: (id: IMeeting) => void
}

const Collapsible: React.FC<IProps> = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen(!isOpen)
    document
      .getElementById('arrowIcon-' + props.meeting.id!)
      ?.classList.toggle(`${styles.arrowActive}`)
    document.getElementById('row-' + props.meeting.id!)!.classList.toggle(`${styles.active}`)
  }

  return (
    <Col className="collapsible xs-12 col-6">
      <Row
        id={`row-${props.meeting.id}`}
        className={styles.row + ' ' + styles.collapsible}
        onClick={toggle}
      >
        <Col>
          <span>{props.meeting.meetingTitle}</span>
        </Col>
        <Col className="d-flex justify-content-end">
          <span className="float-right m-r-5">
            {moment(props.meeting.date).format('DD.MM.yyyy')}
          </span>
          <img
            src={arrowIcon}
            id={`arrowIcon-${props.meeting.id}`}
            alt="arrow"
            className={styles.arrowIcon}
          />
        </Col>
        <Col xs={2}>
          <img
            className={styles.iconHover}
            src={deleteIcon}
            alt="Edit icon"
            onClick={() => {
              props.setDeleteModal(true)
              props.setDeleteData(props.meeting)
            }}
          />
        </Col>
      </Row>
      {isOpen && (
        <div className="content mb-3">
          <div className="card">
            <div className="card-header">
              <Row>
                <Col className={styles.meeting_members}>
                  <b>Supervisor</b> <br />
                  <h6>{props.meeting.supervisorName}</h6>
                </Col>
                <Col className={styles.meeting_members}>
                  <b>Members</b>
                  {props.meeting.meetingMembersNames.map((value) => {
                    return <div>{value}</div>
                  })}
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-muted">Notes</h6>
              <p className="card-text">{props.meeting.notes}</p>
            </div>
          </div>
        </div>
      )}
    </Col>
  )
}

export default Collapsible
