export class Filter {
  key?: string
  value?: string
  label?: string

  constructor(key: string, value: string, label?: string) {
    this.key = key
    this.value = value
    this.label = label
  }
}
