import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'
import Container from 'reactstrap/es/Container'
import { Col, Row } from 'reactstrap'
import Holidays from '../home/timetracks/holidays/Holidays'
import { useContext, useEffect, useState } from 'react'
import { IHoliday } from 'Types/Holidays/IHoliday'
import holidayApi from 'services/holidayApi'
import moment from 'moment'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import { Filter } from 'Types/Filter'
import absenceApi from 'services/absenceApi'
import {
  AbsenceReason,
  IProjectMissingTimetrack,
  ISickLeave,
  IUserAbsence,
} from 'Types/Absences/Absence'
import timeTrackApi from 'services/timeTrackApi'
import Filters from './components/Filters'
import Info from './components/info/Info'
import _ from 'lodash'
import TableDate from './components/TableDate'
import Legend from './components/Legend/Legend'
import LoadingSpinner from 'components/public/landing/loadingSpinner/LoadingSpinner'
import { showUserMissingTimetracks, showUserSickDays } from './utils/utils'

const TimetrackDetails = () => {
  const [holidays, setHolidays] = useState<IHoliday[] | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [numberOfVacationDays, setNumberOfVacationDays] = useState<number | undefined>(undefined)
  const [filters, setFilters] = useState<Filter[]>([
    {
      label: 'Start date',
      key: 'startDate',
      value: moment().startOf('month').format('YYYY-MM-DD'),
    },
    {
      label: 'End date',
      key: 'endDate',
      value: moment().endOf('month').format('YYYY-MM-DD'),
    },
  ])
  const [projectMissingTimetracks, setProjectMissingTimetracks] = useState<
    IProjectMissingTimetrack[] | undefined
  >(undefined)
  const [absences, setAbsences] = useState<IUserAbsence | null>(null)
  const [sickLeaveDays, setSickLeaveDays] = useState<ISickLeave[] | null>(null)
  const snackbarContext = useContext(SnackbarContext)
  const holidayStartDate = moment().startOf('month').format('YYYY-MM-DD')
  const holidayEndDate = moment().endOf('month').format('YYYY-MM-DD')

  useEffect(() => {
    getAllHolidaysForCurrentMonth()
  }, [])

  useEffect(() => {
    const projectId = filters.find((filter) => filter.key === 'projectId')?.value
    const startDate = moment(filters.find((filter) => filter.key === 'startDate')?.value).format(
      'YYYY-MM-DD'
    )
    const endDate = moment(filters.find((filter) => filter.key === 'endDate')?.value).format(
      'YYYY-MM-DD'
    )
    if (projectId && startDate && endDate) {
      getAllUserAbsences(projectId, startDate, endDate)
      getMissingTimetracksForProject(
        projectId,
        startDate,
        moment(endDate).get('month') !== moment().get('month')
          ? endDate
          : moment().format('YYYY-MM-DD')
      )
      getAllSickDays(projectId, startDate, endDate)
    }
    projectId && startDate && endDate && getSumHolidaysForProject(projectId, startDate, endDate)
    if (!projectId) {
      setAbsences(null)
      setNumberOfVacationDays(undefined)
    }
  }, [filters])

  async function getAllSickDays(projectId: string, startDate: string, endDate: string) {
    try {
      setLoading(true)
      const response = await absenceApi.getAbsencesReasonForProject(
        projectId,
        startDate ?? moment().startOf('month').format('YYYY-MM-DD'),
        endDate ?? moment().endOf('month').format('YYYY-MM-DD'),
        AbsenceReason.SICK_LEAVE
      )
      setSickLeaveDays(response)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, (e as any).message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  async function getMissingTimetracksForProject(
    projectId: string,
    startDate: string,
    endDate: string
  ) {
    try {
      setLoading(true)
      const response = await timeTrackApi.getProjectMissingTimetracks(
        projectId,
        startDate ?? moment().startOf('month').format('YYYY-MM-DD'),
        endDate ?? moment().endOf('month').format('YYYY-MM-DD')
      )
      setProjectMissingTimetracks(response)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, (e as any).message, 'danger')
    } finally {
      setLoading(false)
    }
  }
  async function getSumHolidaysForProject(projectId: string, startDate: string, endDate: string) {
    try {
      setLoading(true)
      const response = await absenceApi.getSumAbsencesForProject(
        projectId,
        startDate ?? moment().startOf('month').format('YYYY-MM-DD'),
        endDate ?? moment().endOf('month').format('YYYY-MM-DD')
      )
      setNumberOfVacationDays(response.numberOfVacationDays)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, (e as any).message, 'danger')
    } finally {
      setLoading(false)
    }
  }
  async function getAllHolidaysForCurrentMonth() {
    try {
      setLoading(true)
      const response = await holidayApi.getAllHolidaysBetweenDates(
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      )
      setHolidays(response)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, (e as any).message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  async function getAllUserAbsences(projectId: string, startDate: string, endDate: string) {
    try {
      // var response = await absenceApi.getAbsencesForUser(userId);
      var response = await absenceApi.getAbsencesForProject(projectId, startDate, endDate)
      setAbsences(response)
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  return (
    <Container>
      <GenericContentPage
        header={{
          title: 'Time Tracker Details',
        }}
      >
        <div className="my-component">
          <Row className="my-component__row">
            <Col className="my-component__holidays-col">
              <Holidays
                holidays={holidays}
                holidayStartDate={holidayStartDate}
                holidayEndDate={holidayEndDate}
              />
            </Col>
            <Col className="my-component__filter-col">
              <Filters setFilters={setFilters} filters={filters} setLoading={setLoading} />
            </Col>
            <Col>
              {absences?.projectId && (
                <Info
                  startDate={moment(
                    filters.find((filter) => filter.key === 'startDate')?.value
                  ).format('DD.MM.YYYY.')}
                  endDate={moment(filters.find((filter) => filter.key === 'endDate')?.value).format(
                    'DD.MM.YYYY.'
                  )}
                  projectName={absences?.projectName}
                  numberOfVacationDays={numberOfVacationDays ?? undefined}
                  loading={loading}
                />
              )}
            </Col>
          </Row>

          {absences?.projectId && (
            <Row className="my-component__row">
              <Col>
                <Legend />
              </Col>
            </Row>
          )}
          <Row className="my-component__row">
            <Col className="my-component__table-col">
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <LoadingSpinner />
                </div>
              ) : (
                <table className="my-component__table">
                  {absences?.projectId && (
                    <>
                      <thead className="my-component__thead">
                        <tr className="my-component__thead-row">
                          <th className="my-component__thead-cell">Employee</th>
                          <th className="my-component__thead-cell">Details</th>
                        </tr>
                      </thead>
                      {absences?.userVacation?.map(({ user, absences: userAbsences }, index) => (
                        <tbody key={`${user.fullName}-${user.id}`} className="my-component__tbody">
                          <tr className="my-component__user-row">
                            <th className="my-component__user-label">{user.fullName}</th>
                            <td className="my-component__user-data">
                              {userAbsences.length > 0 && (
                                <td className="my-component__annual-leave-row">
                                  <td
                                    className="my-component__timetrack-text d-flex"
                                    style={{ gap: '4px' }}
                                  >
                                    {userAbsences.map((absence) => (
                                      <TableDate userId={user.id} type={'VACATION'}>
                                        {absence.absenceFrom && absence.absenceTo && (
                                          <div key={`${absence.absenceReason}-${absence.id}`}>
                                            {absence.absenceFrom !== absence.absenceTo ? (
                                              <>
                                                {moment(absence.absenceFrom).format('DD.MM.')} -{' '}
                                                {moment(absence.absenceTo).format('DD.MM.')}
                                              </>
                                            ) : (
                                              <>{moment(absence.absenceFrom).format('DD.MM.')}</>
                                            )}
                                          </div>
                                        )}
                                      </TableDate>
                                    ))}
                                  </td>
                                </td>
                              )}
                              <td className="my-component__timetrack-row">
                                <td
                                  className="my-component__timetrack-text d-flex"
                                  style={{ gap: '4px' }}
                                >
                                  {showUserSickDays(user.id, sickLeaveDays)?.map((dates) => {
                                    const firstDate = moment(dates[0]).format('DD.MM.')
                                    const lastDate = moment(dates[dates.length - 1]).format(
                                      'DD.MM.'
                                    )
                                    return (
                                      <TableDate
                                        userId={user.id}
                                        type={'SICK_LEAVE'}
                                        key={'sick-leave' + firstDate + '-' + user.id}
                                      >
                                        {firstDate === lastDate
                                          ? firstDate
                                          : `${firstDate} - ${lastDate}`}{' '}
                                      </TableDate>
                                    )
                                  })}
                                </td>
                              </td>
                              <td className="my-component__timetrack-row">
                                <td
                                  className="my-component__timetrack-text d-flex"
                                  style={{ gap: '4px' }}
                                >
                                  {showUserMissingTimetracks(
                                    user.id,
                                    projectMissingTimetracks
                                  )?.map((dates) => {
                                    const firstDate = moment(dates[0]).format('DD.MM.')
                                    const lastDate = moment(dates[dates.length - 1]).format(
                                      'DD.MM.'
                                    )
                                    return (
                                      <TableDate
                                        userId={user.id}
                                        type={'MISSING_TIMETRACK'}
                                        key={'missing-timetrack-' + firstDate + '-' + user.id}
                                      >
                                        {firstDate === lastDate
                                          ? firstDate
                                          : `${firstDate} - ${lastDate}`}
                                      </TableDate>
                                    )
                                  })}
                                </td>
                              </td>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </>
                  )}
                </table>
              )}
            </Col>
          </Row>
        </div>
      </GenericContentPage>
    </Container>
  )
}

export default TimetrackDetails
