import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import absenceApi from 'services/absenceApi'
import addIcon from 'assets/img/add-icon.svg'
import editIcon from 'assets/img/edit-icon.svg'
import { Absence, AbsenceRequest, IUserAbsence } from 'Types/Absences/Absence'
import AbsenceCreateModal from './AbsenceCreateModal'
import 'components/ui/fullCalendar.css'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'
import { TimelineGroupBase, TimelineItemBase } from 'react-calendar-timeline'
import moment, { Moment } from 'moment'
import CustomTimeLine from './customTimeline/CustomTimeline'
import userApi from 'services/userApi'
import AbsenceSkeleton from './absenceSkeleton/AbsenceSkeleton'
import DeleteModal from 'components/ui/modals/DeleteModal'
import { Filter } from 'Types/Filter'
import { ReportTimeTrack } from 'Types/Reports/ReportTimeTrack'
import reportsApi from 'services/reportsApi'
import { ILabel } from 'Types/ILabel'
import FilterForm from './filterForm/FilterForm'
import Vacation from './Vacation'
import VacationCreateModal from './VacationCreateModal'
import MonthSelector from 'components/ui/monthSelector/MonthSelector'

const AbsencesLayout: React.FC = () => {
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [createVacationsModal, setCreateVacationsModal] = useState<boolean>(false)
  const [absence, setAbsence] = useState<AbsenceRequest>()
  const [absences, setAbsences] = useState<TimelineItemBase<Moment>[]>([])
  const [absencesEmpty, setAbsencesEmpty] = useState<boolean>(false)
  const [users, setUsers] = useState<TimelineGroupBase[]>([])
  const [formUsers, setFormUsers] = useState<ILabel[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [data, setData] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const snackbarContext = useContext(SnackbarContext)

  const [timeTrackReports, setTimeTrackReports] = useState<ReportTimeTrack[]>([])
  const [filters, setFilters] = useState<Filter[]>([])
  const [newVacationDays, setNewVacationDays] = useState<Number>(0)
  const [oldVacationDays, setOldVacationDays] = useState<Number>(0)

  // run only on the first render
  useEffect(() => {
    setIsAdmin(localStorage.getItem('IS_ADMIN') === 'true' ? true : false)
    getAllAbsences()
    getAllUsers()
    getUserVacationDays()
  }, [])

  useEffect(() => {
    getFilteredAbsences(filters)
  }, [filters])

  function getFilteredAbsences(filters: Filter[]) {
    if (filters.length) {
      if (filters.some((filter) => filter.key == 'projectId')) {
        getFilteredProjects(filters)
      } else if (filters.some((filter) => filter.key == 'userId')) {
        const query = filters.find((filter) => filter.key === 'userId')
        query && getFilteredUser([query])
      } else if (filters.some((filter) => filter.key == 'clientId')) {
        const query = filters.find((filter) => filter.key === 'userId')
        query && getFilteredClients([query])
      }
      setLoading(false)
    } else {
      getAllAbsences()
      getAllUsers()
      getUserVacationDays()
    }
  }

  async function getFilteredProjects(filters: Filter[]) {
    try {
      const projectId = filters.find((filter) => filter.key === 'projectId')?.value ?? ''
      const startDate =
        filters.find((filter) => filter.key === 'startDate')?.value ?? moment().format('YYYY-MM-DD')
      const endDate =
        filters.find((filter) => filter.key === 'endDate')?.value ?? moment().format('YYYY-MM-DD')
      var response = await absenceApi.getAbsencesForProject(projectId, startDate, endDate)
      // var absences = setEvents(response)
      var users = setUsersForFilter(response)
      setAbsences(absences)
      setUsers(users)
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function getFilteredClients(filters: Filter[]) {
    try {
      var response = await absenceApi.getAbsencesForClient(filters[0].value!)
      var absences = setEvents(response)
      var users = setUsersForFilter(response)
      setAbsences(absences)
      setUsers(users)
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function getFilteredUser(filters: Filter[]) {
    try {
      var response = await absenceApi.getAbsencesForUser(filters[0].value!)
      var absences = setEvents(response)
      var user = setUsersForFilter(response)
      setUsers(user)
      setAbsences(absences)
      if (absences.length == 0) {
        setLoading(true)
        setAbsencesEmpty(true)
      }
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  function setUsersForFilter(response: any) {
    let temp: any[] = []
    response.forEach((absence: Absence) =>
      temp.push({
        id: absence.user.id,
        title: `${absence.user.fullName}`,
        stackItems: true,
      })
    )
    //console.log("temp -> " , temp);
    temp = getUnique(temp, 'id')
    return temp
  }

  async function getUserVacationDays() {
    try {
      // var response = await absenceApi.getAbsencesForUser(userId);
      var response = await userApi.getUserVacations(Number(localStorage.getItem('USERID')))
      setNewVacationDays(response.newVacationDays)
      setOldVacationDays(response.oldVacationDays)
      //setAbsences(absences);
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function getAllAbsences() {
    try {
      // var response = await absenceApi.getAbsencesForUser(userId);
      var response = await absenceApi.getAbsences()
      var absences = setEvents(response)
      setAbsences(absences)
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function deleteAbsence(absenceId: number) {
    try {
      await absenceApi.deleteAbsence(absenceId)
      await getAllAbsences()
      await getUserVacationDays()
      snackbarContext?.setShowSnackbarData(true, 'Absence deleted', 'success')
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function getAllUsers() {
    try {
      var temp = new Array<TimelineGroupBase>()
      var response = await userApi.getUsers()
      response.forEach((user) =>
        temp.push({ id: user.id, title: `${user.fullName}`, stackItems: true })
      )
      setUsers(temp)

      const usersArray = temp.map((user: TimelineGroupBase) => {
        return new ILabel(user.title as string, user.id)
      })
      setFormUsers(usersArray)

      setLoading(false)
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  function setEvents(absences: Absence[]): TimelineItemBase<Moment>[] {
    var events: TimelineItemBase<Moment>[] = []

    absences.forEach((a) => {
      var event: TimelineItemBase<Moment> = {
        id: a.id,
        group: a.user.id,
        title: a.absenceReason,
        start_time: moment(a.absenceFrom),
        end_time: moment(a.absenceTo).add(1, 'day'),
        className: 'timeline-item-' + `${a.absenceReason}`,
      }
      events.push(event)
    })
    return events
  }

  function getUnique(array: any[], key: any) {
    if (typeof key !== 'function') {
      const property = key
      key = function (item: any) {
        return item[property]
      }
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item)
          if (!map.has(k)) map.set(k, item)
          return map
        }, new Map())
        .values()
    )
  }

  function setLoadingFromChild() {
    setFilters([])
  }

  function getTimeTrackReports() {
    reportsApi.getTimetracksReports(filters).then((response: any) => {
      setTimeTrackReports(response)
      setLoading(false)
    })
  }

  function handleUserVacationDays() {
    setCreateVacationsModal(true)
  }
  return (
    <Container>
      {isAdmin && (
        <button className="addButton" style={{ float: 'right' }} onClick={handleUserVacationDays}>
          <img className="addButtonIcon" src={editIcon} alt="Add icon" />
          User vacation days
        </button>
      )}
      <GenericContentPage
        header={{
          title: 'Absences',
          buttonFunc: setCreateModal,
          buttonTitle: 'Add Absence',
          icon: addIcon,
        }}
      >
        <>
          {loading ? (
            <AbsenceSkeleton parentCallback={setLoadingFromChild} absencesEmpty={absencesEmpty} />
          ) : (
            <div style={{ marginBottom: '6rem' }}>
              <Row>
                <Col md={3}>
                  <Vacation
                    title={'New Vacation days'}
                    oldVacationDays={false}
                    vacationDays={newVacationDays}
                  />
                </Col>
                <Col md={3}>
                  <Vacation
                    title={'Old Vacation days'}
                    oldVacationDays={true}
                    vacationDays={oldVacationDays}
                  />
                </Col>
              </Row>
              <FilterForm
                setFilters={setFilters}
                filters={filters}
                setLoading={setLoading}
                users={formUsers}
              />
              <CustomTimeLine
                absences={absences}
                users={users}
                setDeleteModal={setDeleteModal}
                setDeleteData={setData}
              />
            </div>
          )}
          <DeleteModal
            isOpen={deleteModal}
            setDeleteModal={setDeleteModal}
            setDeleteData={setData}
            data={data}
            delete={deleteAbsence}
          />
          <AbsenceCreateModal
            isOpen={createModal}
            setCreateModal={setCreateModal}
            absence={absence}
            getUserAbsences={getAllAbsences}
            getUserVacationDays={getUserVacationDays}
            setAbsenceRequest={setAbsence}
          />
          <VacationCreateModal
            isOpen={createVacationsModal}
            setCreateVacationModal={setCreateVacationsModal}
            users={formUsers}
          />
        </>
      </GenericContentPage>
    </Container>
  )
}

export default AbsencesLayout
