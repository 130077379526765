import { ITimetrackInterface } from 'Types/TimeTrack/ITimeTrack'

export function sortTimeTracks(tracks: ITimetrackInterface[]) {
  var array: any[] = []
  var last = tracks[0]
  tracks
    .sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
    .forEach((track: ITimetrackInterface, i) => {
      if (i !== 0 && track.startDate === last.startDate) {
        var a: any[] = array.pop()
        a.push(track)
        array.push(a)
      } else {
        var a: any[] = []
        a.push(track)
        array.push(a)
      }
      last = track
    })
  return array.reverse()
}
