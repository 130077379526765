import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'reactstrap'

export interface AbsenceSkeletonProps {
  parentCallback: () => void
  absencesEmpty: boolean
}

const AbsenceSkeleton: React.FC<AbsenceSkeletonProps> = (props: AbsenceSkeletonProps) => {
  return (
    <Container>
      {props.absencesEmpty ? (
        <div>
          <p style={{ fontSize: '20px' }}>No absences found</p>
          <div>
            <button onClick={props.parentCallback} className="addButton" style={{ float: 'right' }}>
              All Absences
            </button>
          </div>
        </div>
      ) : (
        <Skeleton height={160} />
      )}
    </Container>
  )
}

export default AbsenceSkeleton
