import React, { useContext } from 'react'
import { Alert } from 'reactstrap'
import { SnackbarContext } from './context/Snackbar'
import styles from './alert.module.scss'

const SnackbarAlert = () => {
  const snackbarContext = useContext(SnackbarContext)

  const onDismiss = () => snackbarContext!.setVisible(false)
  return (
    <Alert
      style={{ textAlign: 'center' }}
      className={styles.alert}
      isOpen={snackbarContext!.visible}
      toggle={onDismiss}
      color={snackbarContext!.type}
      fade={true}
    >
      {snackbarContext?.message}
    </Alert>
  )
}

export default SnackbarAlert
