import React, { useState } from 'react'

interface SnackbarContextInterface {
  visible: boolean
  setVisible: (value: boolean) => void
  message: string
  setMessage: (value: string) => void
  type: string
  setType: (value: string) => void
  setShowSnackbarData: (visible: boolean, message?: string, type?: string) => void
}

export const SnackbarContext = React.createContext<SnackbarContextInterface | null>(null)

export const SnackbarProvider = (props: any) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [type, setType] = useState<string>('default')

  if (visible) {
    setTimeout(function () {
      setVisible(false)
    }, 3000)
  }
  const setShowSnackbarData = (
    visibleParam: boolean,
    messageParam?: string,
    typeParam?: string
  ) => {
    visibleParam && setVisible(visibleParam)
    messageParam && setMessage(messageParam)
    typeParam && setType(typeParam)
  }
  return (
    <SnackbarContext.Provider
      value={{
        visible,
        setVisible,
        message,
        setMessage,
        type,
        setType,
        setShowSnackbarData,
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  )
}
