import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'
import MonthSelector from 'components/ui/monthSelector/MonthSelector'
import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import reportsApi from 'services/reportsApi'
import { Filter } from 'Types/Filter'
import { ReportTimeTrack } from 'Types/Reports/ReportTimeTrack'
import ReportsSkeleton from './ReportsSkeleton'
import ReportsTimeTrack from './ReportsTimeTrack'

const ReportsLayout: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [timeTrackReports, setTimeTrackReports] = useState<ReportTimeTrack[]>([])
  const [filters, setFilters] = useState<Filter[]>([])

  useEffect(() => {
    if (filters.length > 0) {
      getTimeTrackReports()
    }
  }, [filters])

  function getTimeTrackReports() {
    reportsApi.getTimetracksReports(filters).then((response) => {
      setTimeTrackReports(response)
      setLoading(false)
    })
  }

  return (
    <Container>
      {/* generic content page sets same header layout and renders different content as React FC */}
      <GenericContentPage header={{ title: 'Reports' }}>
        <>
          {loading ? (
            <>
              <MonthSelector monthActive={true} filters={filters} setFilters={setFilters} />
              <ReportsSkeleton />
            </>
          ) : (
            <>
              <MonthSelector monthActive={true} filters={filters} setFilters={setFilters} />
              <ReportsTimeTrack
                getTimeTrackReports={getTimeTrackReports}
                timeTrackReports={timeTrackReports}
                setFilters={setFilters}
                filters={filters}
              />
            </>
          )}
        </>
      </GenericContentPage>
    </Container>
  )
}

export default ReportsLayout
