import SortableTable from 'components/ui/SortableTable'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import clientApi from 'services/clientApi'
import { IClient } from 'Types/Client/IClient'
import { IClientCreateOrUpdate } from 'Types/Client/IClientCreateOrUpdate'
import { ClientHeader } from 'const/tableConst'
import addIcon from 'assets/img/add-icon.svg'
import DeleteModal from 'components/ui/modals/DeleteModal'
import ClientCreateModal from './ClientCreateModal'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import ClientPage from 'Types/Client/ClientPage'
import { Pageable } from 'Types/Pagination'
import { SortFilter } from 'Types/SortFilter'
import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'

const Clients: React.FC = () => {
  const snackbarContext = useContext(SnackbarContext)
  const [tableData, setTableData] = useState<ClientPage>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [clientData, setClientData] = useState<IClient>()
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [updateClientData, setUpdateClientData] = useState<IClientCreateOrUpdate>()
  const [sortFilters, setSortFilters] = useState<SortFilter[]>([])

  useEffect(() => {
    getClients({ pageSize: 10, page: 0 })
  }, [sortFilters])

  async function getClients(pageable?: Pageable) {
    try {
      setLoading(true)
      var response = await clientApi.getClientsPageable(pageable, sortFilters)
      setTableData(response)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  async function getClient(clientId: number) {
    try {
      var response = await clientApi.getClient(clientId)
      setUpdateClientData(response)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    }
  }

  async function createClient(client: IClientCreateOrUpdate) {
    try {
      await clientApi.createClient(client)
      await getClients()
      snackbarContext?.setShowSnackbarData(true, 'Client created.', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    }
  }

  async function updateClient(client: IClientCreateOrUpdate) {
    try {
      await clientApi.updateClient(client)
      await getClients()
      snackbarContext?.setShowSnackbarData(true, 'Client updated.', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    }
  }

  async function deleteClient(clientId: number) {
    try {
      await clientApi.deleteClient(clientId)
      await getClients()
      snackbarContext?.setShowSnackbarData(true, 'Client deleted.', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    }
  }

  async function searchClients(searchValue: string) {
    var response = await clientApi.searchClients(searchValue)
    setTableData(response)
  }

  return (
    <Container>
      {/* generic content page sets same header layout and renders different content as React FC */}
      <GenericContentPage
        header={{
          title: 'Clients',
          buttonFunc: setCreateModal,
          buttonTitle: 'Add Client',
          icon: addIcon,
          searchFunc: searchClients,
        }}
      >
        <>
          {tableData && (
            <SortableTable
              headers={ClientHeader}
              data={tableData.content ? tableData.content : []}
              loading={loading}
              setDeleteModal={setDeleteModal}
              setDeleteData={setClientData}
              setUpdateModal={setCreateModal}
              setUpdateData={setIsUpdate}
              getDataForRow={getClient}
              setSortFilters={setSortFilters}
              paginateData={tableData}
              getAllData={getClients}
            />
          )}
          <DeleteModal
            isOpen={deleteModal}
            setDeleteModal={setDeleteModal}
            setDeleteData={setClientData}
            data={clientData}
            delete={deleteClient}
          />
          <ClientCreateModal
            isOpen={createModal}
            setCreateModal={setCreateModal}
            createClient={createClient}
            update={isUpdate}
            clientData={updateClientData}
            setUpdate={setIsUpdate}
            setClientData={setUpdateClientData}
            updateClient={updateClient}
          />
        </>
      </GenericContentPage>
    </Container>
  )
}

export default Clients
