import styles from './styles.module.scss'

const Legend = () => {
  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>Legenda</div> */}
      <div className={styles.items}>
        <div className={styles.item}>
          <div className={`${styles.box} ${styles.itemVacation}`}></div>
          Vacation
        </div>
        <div className={styles.item}>
          <div className={`${styles.box} ${styles.itemMissingTimetrack}`}></div>
          Missing timetrack
        </div>
        <div className={styles.item}>
          <div className={`${styles.box} ${styles.itemSickLeave}`}></div>
          Sick leave
        </div>
      </div>
    </div>
  )
}

export default Legend
