import jwt_decode from "jwt-decode";
import { LocalStorageKeyEnum } from "localStorage.enum";

export default {
    getToken: () => {
        const tokenData = localStorage.getItem(LocalStorageKeyEnum.ACCESSTOKEN);
        return tokenData;
    },
    isTokenExpired: () => {
        var tokenData = String(localStorage.getItem(LocalStorageKeyEnum.ACCESSTOKEN));
        if (tokenData) {
            const expiration = jwt_decode(tokenData).exp;
            return expiration < Date.now() / 1000;
        }
        return true;
    }
};
