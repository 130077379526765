import React, { useContext, useEffect, useState } from 'react'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import timeTrackApi from 'services/timeTrackApi'
import projectApi from 'services/projectApi'
import {
  ITimetrackCreateInterface,
  ITimetrackInterface,
  ProjectRole,
  TimeTrackCreate,
} from 'Types/TimeTrack/ITimeTrack'
import { Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { ILabel } from 'Types/ILabel'
import moment, { now } from 'moment'
import { setLabel } from 'components/utils/SelectFunctions'
import { setITimetrack, setTimetrackCreate } from './utils/setTimetrackType'
import { normalizeDate } from 'Helpers/date'
import { capitalizeFirstLetter } from 'components/utils/utils'

interface IProps {
  isOpen: boolean
  setCreateModal: (open: boolean) => void
  timetracks: Array<ITimetrackInterface[]>
  timetrack: TimeTrackCreate | undefined
  lastTimetrack: ITimetrackInterface | undefined
  setTimeTrack: (timeTrack: any) => void
  getUserTimeTracks: (userId: string, startDate: string, endDate: string) => void
  userId: string
  isEdit?: boolean
}

const TimeTrackCreateModal: React.FC<IProps> = (props: IProps) => {
  const [projectsData, setProjectsData] = useState<ILabel[]>([])
  const [projects, setProjects] = useState<ILabel[]>([])
  const [projectRoles, setProjectRoles] = useState<ILabel[]>()
  const [timetrackId, setTimetrackId] = useState<number>()
  const [projectName, setProjectName] = useState<ILabel>()
  const [projectDescription, setProjectDescription] = useState<string>('')
  const [workingHours, setWorkingHours] = useState<number | undefined>(8)
  const [chargeableHours, setChargeableHours] = useState<number | undefined>(8)
  const [projectStartDate, setProjectStartDate] = useState<string>(
    props.isEdit && props.timetrack?.startDate
      ? normalizeDate(moment(props.timetrack?.startDate).toISOString())
      : normalizeDate(moment(now()).toISOString())
  )
  const [projectEndDate, setProjectEndDate] = useState<string>(
    props.isEdit && props.timetrack?.startDate
      ? moment(props.timetrack?.startDate).toISOString()
      : normalizeDate(moment(now()).toISOString())
  )
  const [projectRole, setProjectRole] = useState<ILabel>()
  const [ignoreHoliday, setIgnoreHoliday] = useState<'true' | 'false'>('false')
  const [loading, setLoading] = useState<boolean>(false)
  const snackbarContext = useContext(SnackbarContext)

  useEffect(() => {
    getProjects()
    setRoles()
    setModalData()
  }, [props.isOpen])

  async function getProjects() {
    try {
      var response = await projectApi.getUserProjects(props.userId)
      setProjectsData(setLabel(response, 'projectName'))
    } catch {}
  }

  function createTimeTracks(timeTrack: TimeTrackCreate) {
    setLoading(true)
    timeTrackApi
      .createTimeTrack(timeTrack)
      .then((response) => {
        snackbarContext?.setShowSnackbarData(true, 'Time tracks created', 'success')
        setLoading(false)
        props.getUserTimeTracks(
          props.userId,
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        )
        props.setCreateModal(false)
      })
      .catch((e) => {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
        setLoading(false)
      })
  }

  function updateTimeTrack(timeTrack: TimeTrackCreate) {
    setLoading(true)
    timeTrackApi
      .updateTimeTrack(timeTrack, timetrackId!)
      .then((el) => {
        snackbarContext?.setShowSnackbarData(true, 'Time track updated', 'success')
        setLoading(false)
        props.getUserTimeTracks(
          props.userId,
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        )
        props.setCreateModal(false)
      })
      .catch((e) => {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
        setLoading(false)
      })
  }

  function setRoles() {
    let temp: ILabel[] = []
    Object.values(ProjectRole)
      .filter((value) => typeof value === 'string')
      .map((val, i) => {
        return temp.push({ label: val as string, value: i })
      })
    setProjectRoles(temp)
  }

  function setFormITimetrack(values: ITimetrackCreateInterface) {
    setProjectName({ value: values.project.id, label: values.project.projectName })
    setProjectDescription(values.description)
    setWorkingHours(values.workingHours)
    setChargeableHours(values.chargeableHours)
    setProjectStartDate(
      moment(props.isEdit ? props.timetrack?.startDate : values.startDate).format('YYYY-MM-DD')
    )
    setProjectEndDate(
      moment(props.isEdit ? props.timetrack?.startDate : values.endDate).format('YYYY-MM-DD')
    )
    setProjectRole({ value: values.projectRole, label: values.projectRole.toString() })
    setIgnoreHoliday(values.ignoreHoliday ? 'true' : 'false')
  }

  function setFormTimetrackCreate(values?: TimeTrackCreate, hasName?: boolean) {
    hasName && setProjectName({ value: values?.projectId, label: '' })
    setProjectDescription(values?.description ?? '')
    setWorkingHours(values?.workingHours ?? 8)
    setChargeableHours(values?.chargeableHours ?? 8)
    setProjectStartDate(moment(props.isEdit ? values?.startDate : now()).format('YYYY-MM-DD'))
    setProjectEndDate(moment(props.isEdit ? values?.startDate : now()).format('YYYY-MM-DD'))
    values?.projectRole &&
      setProjectRole({ value: values?.projectRole, label: values?.projectRole!.toString() })
    setIgnoreHoliday(values?.ignoreHoliday ? 'true' : 'false')
  }

  async function setModalData() {
    if (props.timetrack) {
      //if in edit mode, search for day of clicked timetrack and check for available projects to enter
      let selectedTimetrack: ITimetrackInterface[] = props.timetracks.filter((timetracks) =>
        timetracks.every((tt) => tt.startDate == props.timetrack?.startDate)
      )[0]
      let sorted = projectsData.filter((project) =>
        selectedTimetrack.every((tt) => tt.project.id !== project.value)
      )
      setProjects(sorted)
      //passing timetrack from TimeTracker.tsx
      let yesterdayTimeTrack: ITimetrackInterface =
        props.timetrack && setITimetrack(props.timetrack, projectsData)
      setFormITimetrack(yesterdayTimeTrack)
      setTimetrackId(props.timetrack.id)
    } else {
      // if not in edit mode
      if (props.timetracks[0] && !props.timetrack && props.lastTimetrack) {
        //if not in edit mode and there's last timetrack record
        if (
          props.timetracks[0] &&
          props.timetracks[0][0].startDate.toString() === moment().format('YYYY-MM-DD')
        ) {
          //check if there's an timetrack entry for today, retun available or none
          let sorted = projectsData.filter((project) =>
            props.timetracks[0].every((tt) => tt.project.id !== project.value)
          )
          setProjects(sorted)
          //If not in edit mode, check if there is yesterdays timetrack and set it to the last one in the day
          let yesterdayTimeTrack: TimeTrackCreate =
            props.lastTimetrack && setTimetrackCreate(props.lastTimetrack)
          sorted.length
            ? setProjectName({ value: sorted[0].value, label: sorted[0].label })
            : setProjectName(undefined)
          sorted.length
            ? setFormTimetrackCreate(yesterdayTimeTrack)
            : setFormTimetrackCreate(new TimeTrackCreate())
          setTimetrackId(props.timetracks[0][0].id)
        } else {
          //if there's no created timetracks for today, set projects to projectsData
          let yesterdayTimeTrack: TimeTrackCreate | null
          if (props.lastTimetrack.startDate.toString() == moment().format('YYYY-MM-DD')) {
            yesterdayTimeTrack = setTimetrackCreate(props.lastTimetrack)
          } else {
            yesterdayTimeTrack = null
          }
          let sorted: ILabel[] = projectsData.filter(
            (project) => yesterdayTimeTrack && yesterdayTimeTrack.projectId !== project.value
          )
          if (sorted.length) {
            setProjects(sorted)
            setProjectName(sorted[0])
          } else {
            setProjects(projectsData)
            props.lastTimetrack
              ? setProjectName({
                  label: props.lastTimetrack.project.projectName,
                  value: props.lastTimetrack.project.id,
                })
              : setProjectName(undefined)
          }
          setFormTimetrackCreate(yesterdayTimeTrack ?? undefined)
          setTimetrackId(props.timetracks[0][0].id)
        }
      } else {
        //if not in edit mode and there's no previous timetracks, i.e. empty timetrack table, then create new timetrack
        setProjects(projectsData)
        let newTimeTrack = new TimeTrackCreate()
        setFormTimetrackCreate(newTimeTrack, true)
      }
    }
  }

  const TimeTrackShema = Yup.object().shape({
    projectId: Yup.number().required('Name is required').nullable(),
    description: Yup.string().required('Description is required'),
    workingHours: Yup.number().required('Working hours are required').nullable(),
    chargeableHours: Yup.number().required('Chargeable hours are required').nullable(),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string().required('End date is required'),
    projectRole: Yup.string().required('ProjectRole is required'),
    ignoreHoliday: Yup.string().required('Holiday ignore is required'),
  })

  function handleStartDateChange(date: string, setFieldValue: any) {
    let selectedTimetrack: ITimetrackInterface[] = props.timetracks.filter((timetracks) =>
      timetracks.every((tt) => tt.startDate.toString() == date)
    )[0]
    setProjectEndDate(date)
    setFieldValue('endDate', date)
    if (selectedTimetrack) {
      const projectsWithoutTimetrackOnSelectedDay = projectsData.filter((project) =>
        selectedTimetrack.every((tt) => tt.project.id !== project.value)
      )
      setProjects(projectsWithoutTimetrackOnSelectedDay)
      if (projectsWithoutTimetrackOnSelectedDay.length > 0) {
        setProjectName({
          value: projectsWithoutTimetrackOnSelectedDay.at(0)?.value,
          label: projectsWithoutTimetrackOnSelectedDay.at(0)?.label,
        })
      } else {
        setProjectName({ value: '', label: '' })
        setFieldValue('projectId', undefined)
      }
    } else {
      setProjects(projectsData)
    }
  }

  function handleEndDateChange(date: string, setFieldValue: any) {
    let selectedTimetrack: ITimetrackInterface[] = props.timetracks.filter((timetracks) =>
      timetracks.every((tt) => tt.endDate.toString() == date)
    )[0]
    if (selectedTimetrack) {
      const projectsWithoutTimetrackOnSelectedDay = projectsData.filter((project) =>
        selectedTimetrack.every((tt) => tt.project.id !== project.value)
      )
      setProjects(projectsWithoutTimetrackOnSelectedDay)
      if (projectsWithoutTimetrackOnSelectedDay.length > 0) {
        setProjectName({
          value: projectsWithoutTimetrackOnSelectedDay.at(0)?.value,
          label: projectsWithoutTimetrackOnSelectedDay.at(0)?.label,
        })
      } else {
        setProjectName({ value: '', label: '' })
        setFieldValue('projectId', undefined)
      }
    } else {
      setProjects(projectsData)
    }

    // setFieldValue('projectId', undefined);
    // setProjectName({ value: '', label: '' });
  }

  function customHandleChange(e: any, setFieldValue: any, fieldName: string): void {
    if (e) {
      switch (fieldName) {
        case 'projectId': {
          setFieldValue(fieldName, e.value)
          setProjectName({ value: e.value, label: e.label })
          break
        }
        case 'description': {
          setFieldValue(fieldName, e.target.value)
          setProjectDescription(e.target.value)
          break
        }
        case 'workingHours': {
          setFieldValue(fieldName, e.target.value)
          setWorkingHours(e.target.value)
          break
        }
        case 'chargeableHours': {
          setFieldValue(fieldName, e.target.value)
          setChargeableHours(e.target.value)
          break
        }
        case 'startDate': {
          setFieldValue(fieldName, e.target.value)
          setProjectStartDate(e.target.value)
          handleStartDateChange(e.target.value, setFieldValue)
          break
        }
        case 'endDate': {
          setFieldValue(fieldName, e.target.value)
          setProjectEndDate(e.target.value)
          handleEndDateChange(e.target.value, setFieldValue)
          break
        }
        case 'projectRole': {
          setFieldValue(fieldName, e.label)
          setProjectRole({ value: e.label, label: e.label })
          break
        }
        case 'ignoreHoliday': {
          setFieldValue(fieldName, e.label)
          setIgnoreHoliday(e.target.value)
          break
        }
      }
    } else {
      setFieldValue(fieldName, undefined)
    }
  }

  return (
    <Modal isOpen={props.isOpen} centered={true} contentClassName="modalContent" size="md">
      <ModalBody>
        <Container>
          <h3 className="modalTitle">Log time</h3>
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={{
              projectId: projectName?.value,
              description: projectDescription,
              workingHours: workingHours,
              chargeableHours: chargeableHours,
              startDate: projectStartDate,
              endDate: projectEndDate,
              projectRole: projectRole?.value,
              ignoreHoliday: ignoreHoliday,
            }}
            onSubmit={async (values: any) => {
              let newTimetrack: TimeTrackCreate = values
              newTimetrack.ignoreHoliday = values.ignoreHoliday == 'true' ? true : false
              newTimetrack.userId = parseInt(props.userId)
              if (props.timetrack) {
                updateTimeTrack(newTimetrack)
              } else {
                createTimeTracks(newTimetrack)
              }
            }}
            validationSchema={TimeTrackShema}
          >
            {(props2) => {
              const { touched, errors, handleBlur, setFieldValue } = props2
              return (
                <Form>
                  <FormGroup>
                    <Label className="modalLabel" for="projectId">
                      Project
                    </Label>
                    <Select
                      options={projects}
                      value={projectName}
                      onChange={(e: any) => customHandleChange(e, setFieldValue, 'projectId')}
                      onBlur={handleBlur}
                      placeholder="Select project..."
                      name="projectId"
                      type="text"
                    />
                    {/* {errors.projectId && touched.projectId && <div className='input-feedback'>{errors.projectId}</div>} */}
                  </FormGroup>
                  <FormGroup>
                    <Label className="modalLabel" for="description">
                      Description
                    </Label>
                    <Input
                      className="modalTextArea"
                      type="textarea"
                      value={projectDescription}
                      placeholder="Add description..."
                      name="description"
                      onBlur={handleBlur}
                      onChange={(e) => customHandleChange(e, setFieldValue, 'description')}
                    />
                    {/* {errors.description && touched.description && (
                      <div className='input-feedback'>{errors.description}</div>
                    )} */}
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="modalLabel" for="workingHours">
                          Time spent
                        </Label>
                        <Input
                          className="modalInput"
                          type="number"
                          value={workingHours}
                          placeholder="0"
                          name="workingHours"
                          onBlur={handleBlur}
                          onChange={(e) => customHandleChange(e, setFieldValue, 'workingHours')}
                          min={0}
                          step={0.5}
                        />
                        {errors.workingHours && touched.workingHours && (
                          <div className="input-feedback">{errors.workingHours.toString()}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="modalLabel" for="chargeableHours">
                          Chargeable time
                        </Label>
                        <Input
                          className="modalInput"
                          type="number"
                          value={chargeableHours}
                          placeholder="0"
                          name="chargeableHours"
                          onBlur={handleBlur}
                          onChange={(e) => customHandleChange(e, setFieldValue, 'chargeableHours')}
                          min={0}
                          step={0.5}
                        />
                        {errors.chargeableHours && touched.chargeableHours && (
                          <div className="input-feedback">{errors.chargeableHours.toString()}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {' '}
                      <FormGroup>
                        <Label className="modalLabel" for="startDate">
                          Start Date
                        </Label>
                        <Input
                          className="modalInput"
                          type="date"
                          value={projectStartDate}
                          name="startDate"
                          onBlur={handleBlur}
                          onChange={(e) => customHandleChange(e, setFieldValue, 'startDate')}
                          disabled={props.isEdit}
                        />
                        {errors.startDate && touched.startDate && (
                          <div className="input-feedback">{errors.startDate.toString()}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="modalLabel" for="endDate">
                          End Date
                        </Label>
                        <Input
                          className="modalInput"
                          type="date"
                          value={projectEndDate}
                          name="endDate"
                          onBlur={handleBlur}
                          onChange={(e) => customHandleChange(e, setFieldValue, 'endDate')}
                          disabled={props.isEdit}
                        />
                        {errors.endDate && touched.endDate && (
                          <div className="input-feedback">{errors.endDate.toString()}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="modalLabel" for="projectRole">
                      Project Role
                    </Label>
                    <Select
                      options={projectRoles}
                      value={projectRole}
                      formatOptionLabel={(option) =>
                        option.label && capitalizeFirstLetter(option.label)
                      }
                      onChange={(e: any) => customHandleChange(e, setFieldValue, 'projectRole')}
                      onBlur={handleBlur}
                      placeholder="Select project role..."
                      name="projectRole"
                      type="text"
                    />
                    {/* {errors.projectRole && touched.projectRole && (
                      <div className='input-feedback'>{errors.projectRole}</div>
                    )} */}
                  </FormGroup>
                  <FormGroup>
                    <Label className="modalLabel" for="ignoreHoliday">
                      Ignore Holiday
                    </Label>
                    <Row>
                      <Col xs={2}>
                        <Label className="radioOption" for="ignoreHoliday-true">
                          Yes
                        </Label>
                        <Input
                          type="radio"
                          name="ignoreHoliday-true"
                          value={'true'}
                          checked={ignoreHoliday == 'true'}
                          onChange={(e) => customHandleChange(e, setFieldValue, 'ignoreHoliday')}
                        ></Input>
                      </Col>
                      <Col xs={2}>
                        <Label className="radioOption" for="ignoreHoliday-false">
                          No
                        </Label>
                        <Input
                          type="radio"
                          name="ignoreHoliday-false"
                          value={'false'}
                          checked={ignoreHoliday == 'false'}
                          onChange={(e) => customHandleChange(e, setFieldValue, 'ignoreHoliday')}
                        ></Input>
                      </Col>
                    </Row>
                  </FormGroup>
                  <Row className="modalRowCenter">
                    <Col>
                      <button
                        className="cancelButton modalButtonMargin"
                        type="button"
                        onClick={() => {
                          props.setCreateModal(false)
                          if (props.timetrack) {
                            props.setTimeTrack(null)
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col>
                      <button
                        className="modalAddButton modalButtonMargin"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? 'Save' : 'Saving...'}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default TimeTrackCreateModal
