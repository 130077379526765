import moment from 'moment-business-days'
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { IHoliday } from 'Types/Holidays/IHoliday'

interface IHolidayProps {
  holidays: IHoliday[] | undefined
  holidayStartDate: string
  holidayEndDate: string
}

export const Holidays = ({ holidays, holidayStartDate, holidayEndDate }: IHolidayProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Holidays from {moment(holidayStartDate).format('DD.MM.YYYY.')} to{' '}
        {moment(holidayEndDate).format('DD.MM.YYYY.')}
      </div>
      <div className={styles.dates}>
        {holidays && holidays?.length > 0 ? (
          holidays?.map((holiday) => {
            return (
              <span className={styles.holidayName} key={holiday.date.toString()}>
                <span>{holiday.name}</span> - {moment(holiday.date).format('DD.MM.YYYY.')}
              </span>
            )
          })
        ) : (
          <span>No holidays this month</span>
        )}
      </div>
    </div>
  )
}

export default Holidays
