import { apiRoutes } from 'const/apiRoutes'
import { METHODS } from 'http'
import { IUser } from 'Types/IUser'
import { IMeeting } from 'Types/Meeting/Meeting'
import { MeetingCreate } from 'Types/Meeting/MeetingCreate'
import { api } from './api'

export default {
  getAllMeetings(): Promise<IMeeting[]> {
    return api.get(apiRoutes.getAllMeetings)
  },

  getUserAndSupervisorMeetings(userId: string): Promise<IMeeting[]> {
    return api.get(apiRoutes.getUserAndSupervisorMeetings(userId))
  },

  createMeeting(meeting: MeetingCreate): Promise<IMeeting> {
    return api.post(apiRoutes.createMeeting, meeting)
  },

  deleteMeeting(meetingId: number) {
    return api.delete(apiRoutes.deleteMeeting(meetingId))
  },
}
