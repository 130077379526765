import React from 'react'
import styles from './styles.module.scss'

const LoadingSpinner: React.FC = () => {
  return (
    <div className={styles.spinner}>
      <span className={styles.spinnerInner1}></span>
      <span className={styles.spinnerInner2}></span>
      <span className={styles.spinnerInner3}></span>
    </div>
  )
}

export default LoadingSpinner
