import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import React, { useContext, useState } from 'react'
import absenceApi from 'services/absenceApi'
import { AbsenceReason, AbsenceRequest } from 'Types/Absences/Absence'
import { Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LocalStorageKeyEnum } from 'localStorage.enum'
import moment from 'moment'
import LoadingSpinner from 'components/public/landing/loadingSpinner/LoadingSpinner'
import { capitalizeFirstLetter } from 'components/utils/utils'

interface IProps {
  isOpen: boolean
  setCreateModal: (open: boolean) => void
  absence?: AbsenceRequest
  setAbsenceRequest: (absence: any) => void
  getUserAbsences: (userId: string) => void
  getUserVacationDays: (userId: string) => void
}

const AbsenceCreateModal: React.FC<IProps> = (props: IProps) => {
  const snackbarContext = useContext(SnackbarContext)
  const userId = localStorage.getItem(LocalStorageKeyEnum.USERID as string)
  const [loading, setLoading] = useState<boolean>()
  async function createAbsence(absence: AbsenceRequest) {
    try {
      await absenceApi.createAbsence(absence)
      userId && props.getUserVacationDays(userId)
      snackbarContext?.setShowSnackbarData(true, 'Absence created', 'success')
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  async function updateAbsence(absence: AbsenceRequest) {
    try {
      await absenceApi.updateAbsence(absence.id!, absence)
      userId && props.getUserVacationDays(userId)
      snackbarContext?.setShowSnackbarData(true, 'Absence updated', 'success')
    } catch (e) {
      if (e instanceof Error) {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
      }
    }
  }

  const AbsenceValidationShema = Yup.object().shape({
    absenceFrom: Yup.string().required('Date from is required'),
    absenceTo: Yup.string().required('Date until is required'),
    absenceReason: Yup.string().required('Reason is required').nullable(),
  })

  return (
    <Modal isOpen={props.isOpen} centered={true} contentClassName="modalContent" size="md">
      <ModalBody>
        <Container>
          <h3 className="modalTitle">Absence</h3>
          <Formik
            enableReinitialize
            initialValues={props.absence ? props.absence : new AbsenceRequest()}
            onSubmit={async (values) => {
              setLoading(true)
              values.userId = userId
              values.absenceFrom = moment(values.absenceFrom).format(
                'YYYY-MM-DD'
              ) as unknown as Date
              values.absenceTo = moment(values.absenceTo).format('YYYY-MM-DD') as unknown as Date
              if (props.absence) {
                await updateAbsence(values)
                setLoading(false)
              } else {
                await createAbsence(values)
                setLoading(false)
              }
              props.getUserAbsences(userId!)
              props.setCreateModal(false)
            }}
            validationSchema={AbsenceValidationShema}
          >
            {(props2) => {
              const { values, touched, errors, handleChange, handleBlur } = props2
              return (
                <Form>
                  <FormGroup>
                    <Label className="modalLabel" for="absenceFrom">
                      From
                    </Label>
                    <Input
                      className="modalInput"
                      type="date"
                      value={moment(values.absenceFrom).format('YYYY-MM-DD')}
                      name="absenceFrom"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {/* {errors.absenceFrom && touched.absenceFrom && (
                    <div className="input-feedback">{errors.absenceFrom}</div>
                  )} */}
                  </FormGroup>
                  <FormGroup>
                    <Label className="modalLabel" for="absenceTo">
                      Until
                    </Label>
                    <Input
                      className="modalInput"
                      type="date"
                      value={moment(values.absenceTo).format('YYYY-MM-DD')}
                      name="absenceTo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {/* {errors.absenceTo && touched.absenceTo && (
                    <div className="input-feedback">{errors.absenceTo}</div>
                  )} */}
                  </FormGroup>
                  <FormGroup>
                    <Label className="modalLabel" for="absenceReason">
                      Reason
                    </Label>
                    <Input
                      className="modalInput"
                      type="select"
                      name="absenceReason"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option
                        hidden
                        disabled
                        selected={values.absenceReason ? false : true}
                        value=""
                      >
                        Select absence reason
                      </option>
                      {Object.values(AbsenceReason)
                        .filter((value) => typeof value === 'string')
                        .map((value) => {
                          return (
                            <option
                              selected={value === values.absenceReason}
                              key={value}
                              value={value}
                            >
                              {capitalizeFirstLetter(value)}
                            </option>
                          )
                        })}
                    </Input>
                    {errors.absenceReason && touched.absenceReason && (
                      <div className="input-feedback">{errors.absenceReason}</div>
                    )}
                  </FormGroup>
                  <Row className="modalRowCenter">
                    {!loading ? (
                      <>
                        <Col>
                          <button
                            className="cancelButton modalButtonMargin"
                            type="button"
                            disabled={loading}
                            onClick={() => {
                              props.setCreateModal(false)
                              if (props.absence) {
                                props.setAbsenceRequest(null)
                              }
                            }}
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col>
                          <button
                            className="modalAddButton modalButtonMargin"
                            type="submit"
                            disabled={loading}
                          >
                            {props.absence ? 'Update' : 'Add'}
                          </button>
                        </Col>
                      </>
                    ) : (
                      <Col
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <LoadingSpinner />
                      </Col>
                    )}
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default AbsenceCreateModal
