import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Col, Container, Row, Table } from 'reactstrap'
import styles from './styles.module.scss'

const ReportsSkeleton: React.FC = () => {
  return (
    <div className={styles.trackPadding}>
      <Container>
        <Row>
          <Col>
            <h3>
              <Skeleton />
            </h3>
          </Col>
          <Col>
            <h3>
              <Skeleton />
            </h3>
          </Col>
          <Col>
            <h3>
              <Skeleton />
            </h3>
          </Col>
        </Row>
      </Container>
      <Table className={styles.table} style={{ opacity: 0.25 }}>
        <thead className={styles.thead}>
          <tr>
            <th>
              <Skeleton />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default ReportsSkeleton
