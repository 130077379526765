import { ProjectHeader } from 'const/tableConst'
import React, { useContext, useEffect, useState } from 'react'
import projectApi from 'services/projectApi'
import { IProject } from 'Types/Project/IProject'
import SortableTable from 'components/ui/SortableTable'
import { Container } from 'reactstrap'
import addIcon from 'assets/img/add-icon.svg'
import DeleteModal from 'components/ui/modals/DeleteModal'
import ProjectCreateModal from './ProjectCreateModal'
import { IProjectCreate } from 'Types/Project/IProjectCreate'
import { IProjectGet } from 'Types/Project/IProjectGet'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import ProjectPage from 'Types/Project/ProjectPage'
import { Pageable } from 'Types/Pagination'
import { SortFilter } from 'Types/SortFilter'
import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'

const Projects: React.FC = () => {
  const [tableData, setTableData] = useState<ProjectPage>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [projectData, setProjectData] = useState<IProject>()
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [updateProjectBool, setUpdateProjectBool] = useState<boolean>(false)
  const [getProjectData, setGetProjectData] = useState<IProjectGet>()
  const snackbarContext = useContext(SnackbarContext)
  const [sortFilters, setSortFilters] = useState<SortFilter[]>([])

  useEffect(() => {
    getProjects({ pageSize: 10, page: 0 })
  }, [])

  async function getProjects(pageable?: Pageable) {
    try {
      setLoading(true)
      var response = await projectApi.getProjectsPageeable(pageable, sortFilters)
      setTableData(response)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  async function deleteProject(projectId: number) {
    try {
      await projectApi.deleteProject(projectId)
      await getProjects()
      snackbarContext?.setShowSnackbarData(true, 'Project deleted', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    }
  }

  async function createProject(project: IProjectCreate) {
    try {
      await projectApi.createProject(project)
      await getProjects()
      snackbarContext?.setShowSnackbarData(true, 'Project created', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    }
  }

  async function getProject(projectId: number) {
    var response = await projectApi.getProject(projectId)
    setGetProjectData(response)
  }

  async function updateProject(projectId: number, data: IProjectCreate) {
    try {
      await projectApi.updateProject(projectId, data)
      await getProjects()
      snackbarContext?.setShowSnackbarData(true, 'Project updated', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
    }
  }

  async function searchProject(searchValue: string) {
    var response = await projectApi.searchProjects(searchValue)
    setTableData(response)
  }

  return (
    <Container>
      {/* generic content page sets same header layout and renders different content as React FC */}
      <GenericContentPage
        header={{
          title: 'Projects',
          buttonFunc: setCreateModal,
          buttonTitle: 'Add Project',
          icon: addIcon,
          searchFunc: searchProject,
        }}
      >
        {' '}
        <>
          <SortableTable
            headers={ProjectHeader}
            data={tableData ? tableData.content : []}
            paginateData={tableData}
            loading={loading}
            setDeleteModal={setDeleteModal}
            setDeleteData={setProjectData}
            setUpdateModal={setCreateModal}
            setUpdateData={setUpdateProjectBool}
            getDataForRow={getProject}
            setSortFilters={setSortFilters}
            getAllData={getProjects}
          />
          <DeleteModal
            isOpen={deleteModal}
            setDeleteModal={setDeleteModal}
            setDeleteData={setProjectData}
            data={projectData}
            delete={deleteProject}
          />
          <ProjectCreateModal
            isOpen={createModal}
            setCreateModal={setCreateModal}
            createProject={createProject}
            update={updateProjectBool}
            projectData={getProjectData}
            setUpdate={setUpdateProjectBool}
            setUpdateData={setGetProjectData}
            updateProject={updateProject}
          />
        </>
      </GenericContentPage>
    </Container>
  )
}

export default Projects
