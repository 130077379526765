import React from 'react'
import { Modal, ModalBody, Col, Row, Container } from 'reactstrap'
import styles from 'components/ui/modals/modals.module.scss'

interface IProps {
  isOpen: boolean
  setDeleteModal: (open: boolean) => void
  setDeleteData: (data: any) => void
  data: any
  delete: (id: number) => void
}

const DeleteModal: React.FC<IProps> = (props: IProps) => {
  return (
    <Modal isOpen={props.isOpen} centered={true} size="sm" contentClassName="modalContent">
      <ModalBody>
        <Container>
          <Row className={styles.rowCenter}>
            <Col xs="auto">
              <h3 className={styles.deleteModuleText}>Are you sure?</h3>
            </Col>
          </Row>
          <Row className={styles.rowCenter}>
            <Col className={styles.colPadding} xs={{ size: 'auto' }}>
              <button
                className="cancelButton"
                type="button"
                onClick={() => {
                  props.setDeleteModal(false)
                  props.setDeleteData(null)
                }}
              >
                Cancel
              </button>
            </Col>
            <Col className={styles.colPadding} xs={{ size: 'auto' }}>
              <button
                className="deleteButton"
                type="button"
                onClick={() => {
                  props.delete(props.data.id)
                  props.setDeleteData(null)
                  props.setDeleteModal(false)
                }}
              >
                Delete
              </button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default DeleteModal
