import { Form, Formik, Field } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { IUser } from 'Types/IUser'
import { IMeeting } from 'Types/Meeting/Meeting'
import { MeetingCreate } from 'Types/Meeting/MeetingCreate'
import meetingApi from 'services/meetingApi'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import Select from 'react-select'
import userApi from 'services/userApi'
import { setLabel } from 'components/utils/SelectFunctions'
import { ILabel } from 'Types/ILabel'

interface IProps {
  isOpen: boolean
  setCreateModal: (open: boolean) => void
  makeNewMeeting: (userId: number) => void
  employeeData: IUser[]
  getUserMeetings: () => void
}

const MeetingCreateModel: React.FC<IProps> = (props: IProps) => {
  const [meetingTitle, setMeetingTitle] = useState<string>()
  const [meetingUsers, setMeetingUsers] = useState<number[]>([])
  const [supervisor, setSupervisor] = useState<ILabel>()
  const [meetingDate, setMeetingDate] = useState<string>()
  const [meetingNotes, setMeetingNotes] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [usersArray, setUsersArray] = useState<ILabel[]>()

  //let supervisor: ILabel;

  const snackbarContext = useContext(SnackbarContext)

  useEffect(() => {
    getEmployees()
  }, [])

  function selectUser(userId: number) {
    meetingUsers?.push(userId)
    document.getElementById('user-' + userId!)!.classList.toggle(`${styles.active}`)
  }

  // function selectSupervisor(userId: number) {
  //   setMeetingSupervisor(userId);
  //   document.getElementById("supervisor-" + userId!)!.classList.toggle(`${styles.active}`);
  // }

  function customHandleChange(e: any, setFieldValue: any, fieldName: string): void {
    if (e) {
      switch (fieldName) {
        case 'title': {
          setFieldValue(fieldName, e.value)
          setMeetingTitle(e.target.value)
          break
        }
        case 'notes': {
          setFieldValue(fieldName, e.target.value)
          setMeetingNotes(e.target.value)
          break
        }
        case 'date': {
          setFieldValue(fieldName, e.target.value)
          setMeetingDate(e.target.value)
          //handleDateChange(e.target.value, setFieldValue);
          break
        }
      }
    } else {
      setFieldValue(fieldName, undefined)
    }
  }

  function getEmployees() {
    userApi.getUsers().then((response) => {
      setUsersArray(setLabel(response, 'fullName'))
    })
  }

  function createNewMeeting(meeting: MeetingCreate) {
    setLoading(true)
    meetingApi
      .createMeeting(meeting)
      .then((el) => {
        snackbarContext?.setShowSnackbarData(true, 'Meeting created', 'success')
        setLoading(false)
        props.getUserMeetings()
        props.setCreateModal(false)
      })
      .catch((e) => {
        snackbarContext?.setShowSnackbarData(true, e.message, 'danger')
        setLoading(false)
      })
  }

  return (
    <Modal isOpen={props.isOpen} centered={true} contentClassName="modalContent" size="md">
      <ModalBody>
        <Container>
          <h3 className="modalTitle">Choose meeting members</h3>
          <Formik
            enableReinitialize
            initialValues={{
              meetingTitle: meetingTitle,
              meetingMemberIds: meetingUsers,
              supervisorId: supervisor?.value,
              date: meetingDate,
              notes: meetingNotes,
            }}
            onSubmit={(values: any) => {
              let newMeeting: MeetingCreate = values
              //console.log("supervisor value ---> " + supervisor?.label);
              //newMeeting.supervisorId = supervisor?.value;
              createNewMeeting(newMeeting)
            }}
          >
            {(props2) => {
              const { touched, errors, handleBlur, setFieldValue } = props2
              return (
                <Form>
                  <Row>
                    {props.employeeData.map((employee, i) => {
                      return (
                        <Col sm={12} md={6} className={styles.formButton}>
                          <tr key={i}>
                            <td>
                              <button
                                id={`user-${employee.id}`}
                                className={styles.meetingAddButton}
                                onClick={() => selectUser(employee.id)}
                                type="button"
                              >
                                {employee.fullName}
                              </button>
                            </td>
                          </tr>
                        </Col>
                      )
                    })}
                  </Row>
                  <FormGroup>
                    <label className="modalTitle">Choose supervisor</label>

                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 100 }),
                      }}
                      isClearable
                      onBlur={handleBlur}
                      options={usersArray}
                      value={supervisor}
                      onChange={(e: any) => setSupervisor(e)}
                      placeholder="Select supervisor"
                      name="supervisor"
                      type="text"
                    />
                    {/* {errors.supervisorId && touched.supervisorId && (
                      <div className='input-feedback'>{errors.supervisorId}</div>
                    )} */}
                  </FormGroup>
                  {/* <Row>
                    {props.employeeData.map((employee, i) => {
                      return (
                        <Col sm={12} md={6} className={styles.formButton}>
                          <tr key={i}>
                            <td>
                              <button
                                id={`supervisor-${employee.id}`}
                                className={styles.meetingAddButton}
                                onClick={() => selectSupervisor(employee.id)}
                                type="button">
                                {employee.fullName}
                              </button>
                            </td>
                          </tr>
                        </Col>
                      );
                    })}
                  </Row> */}
                  <FormGroup className="m-t-10">
                    <Label className="modalLabel" for="description">
                      Title
                    </Label>
                    <Input
                      className="modalTextArea"
                      type="textarea"
                      value={meetingTitle}
                      placeholder="Meeting Title"
                      name="title"
                      onChange={(e) => customHandleChange(e, setFieldValue, 'title')}
                    />
                  </FormGroup>
                  <FormGroup className="m-t-10">
                    <Label className="modalLabel" for="date">
                      Date
                    </Label>
                    <Input
                      className="modalInput"
                      type="date"
                      value={meetingDate}
                      name="date"
                      onChange={(e) => customHandleChange(e, setFieldValue, 'date')}
                    />
                  </FormGroup>
                  <FormGroup className="m-t-10">
                    <Label className="modalLabel" for="description">
                      Notes
                    </Label>
                    <Input
                      className="modalTextArea"
                      type="textarea"
                      value={meetingNotes}
                      placeholder="Add notes..."
                      name="notes"
                      onChange={(e) => customHandleChange(e, setFieldValue, 'notes')}
                    />
                  </FormGroup>
                  <Row className="modalRowCenter">
                    <button
                      className="cancelButton modalButtonMargin"
                      type="button"
                      onClick={() => {
                        props.setCreateModal(false)
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="modalAddButton modalButtonMargin"
                      type="submit"
                      disabled={loading}
                    >
                      {!loading ? 'Save' : 'Saving...'}
                    </button>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export default MeetingCreateModel
