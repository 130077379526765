import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import damir from 'assets/img/damir.png'

const NotFound = () => {
  return (
    <div className={styles.container404}>
      <span className={styles.message404}>Gdje ćeš?</span>
      <img src={damir} alt="damir :kojikurac:" className={styles.img404} />
      <Link to="/timetracker" style={{ textDecoration: 'none' }}>
        <span className={styles.returnMessage404}>Aj natrag!</span>
      </Link>
      <span className={styles.err}>ERR</span>
      <span className={styles.fof}>404</span>
    </div>
  )
}

export default NotFound
