import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import timeTrackApi from 'services/timeTrackApi'
import { ITimetrackInterface, TimeTrackCreate } from 'Types/TimeTrack/ITimeTrack'
import Track from './Track'
import styles from 'components/private/home/timetracks/timetrack.module.scss'
import addIcon from 'assets/img/add-icon.svg'
import DeleteModal from 'components/ui/modals/DeleteModal'
import { SnackbarContext } from 'components/ui/snackbar/context/Snackbar'
import TimeTrackCreateModal from './TimeTrackCreateModal'
import TimeTrackSkeleton from './TimeTrackSkeleton'
import { LocalStorageKeyEnum } from 'localStorage.enum'
import { WorkingHours } from './workingHours/WorkingHours'
import { WorkingDays } from './workingDays/WorkingDays'
import GenericContentPage from 'components/ui/genericContentPage/GenericContentPage'
import { sortTimeTracks } from './utils/SortTimetracks'
import { MonthSelector } from 'components/ui/monthSelector/MonthSelector'
import { Filter } from 'Types/Filter'
import moment from 'moment'
import holidayApi from 'services/holidayApi'
import Holidays from './holidays/Holidays'
import { IHoliday } from 'Types/Holidays/IHoliday'
import { normalizeDate } from 'Helpers/date'

const TimeTracker: React.FC = () => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [sortedTimeTracks, setSortedTimeTracks] = useState<Array<ITimetrackInterface[]>>([])
  const [lastTimeTrack, setLastTimeTrack] = useState<ITimetrackInterface>()
  const [timeTrackData, setTimeTrackData] = useState<ITimetrackInterface>()
  const [createModal, setCreateModal] = useState<boolean>(false)
  const snackbarContext = useContext(SnackbarContext)
  const [timeTrack, setTimeTrack] = useState<TimeTrackCreate>()
  const [loading, setLoading] = useState<boolean>(true)
  const [workingHours, setWorkingHours] = useState<number>()
  const [filters, setFilters] = useState<Filter[]>([])
  const [user, setUser] = useState<string>(
    localStorage.getItem(LocalStorageKeyEnum.USERID)!.toString()
  )
  const [holidays, setHolidays] = useState<IHoliday[] | undefined>()
  const [holidayStartDate, setHolidayStartDate] = useState(
    normalizeDate(moment().startOf('month').toISOString())
  )
  const [holidayEndDate, setHolidayEndDate] = useState(
    normalizeDate(moment().endOf('month').toISOString())
  )

  useEffect(() => {
    getAllHolidaysForCurrentMonth()
  }, [holidayStartDate])
  useEffect(() => {
    if (filters.length) {
      let userId = filters.find((el) => el.key === 'userId')
        ? filters.find((el) => el.key === 'userId')?.value
        : localStorage.getItem(LocalStorageKeyEnum.USERID)!.toString()
      setUser(userId!)
      let startDate = normalizeDate(
        moment(filters.find((el) => el.key === 'startDate')?.value).toISOString() ??
          moment().startOf('month').toISOString()
      )
      let endDate = normalizeDate(
        moment(filters.find((el) => el.key === 'endDate')?.value).toISOString() ??
          moment().endOf('month').toISOString()
      )
      setHolidayStartDate(startDate)
      setHolidayEndDate(endDate)

      userId && startDate && endDate && getAllTimeTrackForUser(userId, startDate, endDate)
    }
  }, [filters])

  async function getAllHolidaysForCurrentMonth() {
    try {
      setLoading(true)
      const response = await holidayApi.getAllHolidaysBetweenDates(holidayStartDate, holidayEndDate)
      setHolidays(response)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, (e as any).message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  async function getAllTimeTrackForUser(userId: string, startDate: string, endDate: string) {
    try {
      setLoading(true)
      var response = await timeTrackApi.getAllTimeTrackForUser(userId, startDate, endDate)
      const tempLastTimetrack = response.timeTrack[response.timeTrack.length - 1]
      tempLastTimetrack &&
        setLastTimeTrack({
          id: tempLastTimetrack.id,
          description: tempLastTimetrack.description,
          workingHours: tempLastTimetrack.workingHours,
          chargeableHours: tempLastTimetrack.chargeableHours,
          projectRole: tempLastTimetrack.projectRole,
          project: tempLastTimetrack.project,
          startDate: tempLastTimetrack.date,
          endDate: tempLastTimetrack.date,
          ignoreHoliday: false,
        })
      var timeTracksArray = response.timeTrack
        ? sortTimeTracks(
            response.timeTrack.map((t) => ({
              id: t.id,
              description: t.description,
              workingHours: t.workingHours,
              chargeableHours: t.chargeableHours,
              projectRole: t.projectRole,
              project: t.project,
              startDate: t.date,
              endDate: t.date,
              ignoreHoliday: false,
            }))
          )
        : []
      setSortedTimeTracks(timeTracksArray)
      setWorkingHours(response.timePeriodWorkingHoursCount)
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, (e as any).message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  async function deleteTimeTrack(timeTrackId: number) {
    try {
      setLoading(true)
      await timeTrackApi.deleteTimeTrack(timeTrackId)
      let userId = filters.find((el) => el.key === 'userId')
        ? filters.find((el) => el.key === 'userId')?.value
        : localStorage.getItem(LocalStorageKeyEnum.USERID)?.toString()
      let startDate =
        filters.find((el) => el.key === 'startDate')?.value ?? moment().format('YYYY-MM-DD')
      let endDate =
        filters.find((el) => el.key === 'endDate')?.value ?? moment().format('YYYY-MM-DD')
      startDate = normalizeDate(moment(startDate).toISOString())
      endDate = normalizeDate(moment(endDate).toISOString())
      setHolidayStartDate(startDate)
      setHolidayEndDate(endDate)
      await getAllTimeTrackForUser(
        userId ?? localStorage.getItem(LocalStorageKeyEnum.USERID)!.toString(),
        startDate,
        endDate
      )
      snackbarContext?.setShowSnackbarData(true, 'TimeTrack deleted', 'success')
    } catch (e) {
      snackbarContext?.setShowSnackbarData(true, (e as any).message, 'danger')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      {/* generic content page sets same header layout and renders different content as React FC */}
      <GenericContentPage
        header={{
          title: 'Time Tracker',
          buttonFunc: setCreateModal,
          buttonTitle: 'Add Entry',
          icon: addIcon,
        }}
      >
        {loading ? (
          <>
            <MonthSelector monthActive={true} filters={filters} setFilters={setFilters} />
            <TimeTrackSkeleton />
          </>
        ) : (
          <>
            <MonthSelector monthActive={true} filters={filters} setFilters={setFilters} />
            {sortedTimeTracks.length === 0 ? (
              <>
                <Row>
                  <Col md={3}>
                    <Holidays
                      holidays={holidays}
                      holidayStartDate={holidayStartDate}
                      holidayEndDate={holidayEndDate}
                    ></Holidays>
                  </Col>
                </Row>
                <h3 className={styles.noDataText}>No data</h3>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <WorkingHours
                      timetracks={sortedTimeTracks}
                      workingHours={workingHours}
                      holidays={holidays}
                    ></WorkingHours>
                  </Col>
                  <Col>
                    <Holidays
                      holidays={holidays}
                      holidayStartDate={holidayStartDate}
                      holidayEndDate={holidayEndDate}
                    ></Holidays>
                  </Col>
                  <Col md={6}>
                    <WorkingDays timetracks={sortedTimeTracks} holidays={holidays}></WorkingDays>
                  </Col>
                </Row>
                {sortedTimeTracks.slice(0).map((timetrack, i) => {
                  return (
                    <Track
                      key={i}
                      loading={loading}
                      timetracks={timetrack}
                      setDeleteModal={setDeleteModal}
                      setDeleteData={setTimeTrackData}
                      setCreateModal={setCreateModal}
                      setTimeTrack={setTimeTrack}
                    />
                  )
                })}
              </>
            )}
          </>
        )}

        <TimeTrackCreateModal
          isOpen={createModal}
          setCreateModal={setCreateModal}
          timetrack={timeTrack}
          timetracks={sortedTimeTracks}
          lastTimetrack={lastTimeTrack}
          setTimeTrack={setTimeTrack}
          getUserTimeTracks={getAllTimeTrackForUser}
          userId={user}
          isEdit={!!timeTrack}
        />

        <DeleteModal
          isOpen={deleteModal}
          setDeleteModal={setDeleteModal}
          setDeleteData={setTimeTrackData}
          data={timeTrackData}
          delete={deleteTimeTrack}
        />
      </GenericContentPage>
    </Container>
  )
}

export default TimeTracker
