import { api } from 'services/api'
import { AbsenceReason } from 'Types/Absences/Absence'

const apiBase = '/api'

export const apiRoutes = {
  // projects
  getProjects: apiBase + '/projects',
  getProject: (projectId: number) => apiBase + '/projects/' + projectId,
  deleteProject: (projectId: number) => apiBase + '/projects/' + projectId,
  createProject: apiBase + '/projects',
  updateProject: (projectId: number) => apiBase + '/projects/' + projectId,
  searchProjects: (searchValue: string) => apiBase + '/projects?searchValue=' + searchValue,
  getUserProjects: (userId: string) => apiBase + '/projects/user/' + userId,

  // changePassword: ({ email, token, newPassword }: IResetPassword) =>
  //   apiBase + `/account/password-reset/${email}/${token}/${newPassword}`,

  // login
  login: apiBase + '/authenticate/login',

  // clients
  getClients: apiBase + '/clients',
  getClientsPageable: apiBase + '/clients/pageable',
  getClient: (clientId: number) => apiBase + '/clients/' + clientId,
  createClient: apiBase + '/clients',
  updateClient: apiBase + '/clients',
  deleteClient: (clientId: number) => apiBase + '/clients/' + clientId,
  searchClients: (searchValue: string) => apiBase + '/clients?searchValue=' + searchValue,

  // users
  getUsers: apiBase + '/users',
  getEmployees: apiBase + '/users/employee',
  getUserVacations: (userId: number) => apiBase + '/users/vacation?userId=' + userId,
  updateUserVacationDays: (userId: number) => apiBase + '/users/vacation?userId=' + userId,

  // supervisor
  getSupervisorSubordinates: apiBase + '/supervisor',
  makeNewEmployeeSupervisorPair: (employeeId: number, supervisorId: number) =>
    apiBase + '/supervisor?employeeId=' + employeeId + '&supervisorId=' + supervisorId,

  // timetracks
  getAllTimeTrackForUser: (userId: string, startDate: string, endDate: string) =>
    apiBase + '/timetrack?userId=' + userId + '&endDate=' + endDate + '&startDate=' + startDate,
  createTimeTrack: apiBase + '/timetrack',
  updateTimeTrack: (timeTrackId: number) => apiBase + '/timetrack?timeTrackId=' + timeTrackId,
  deleteTimeTrack: (timeTrackId: number) => apiBase + '/timetrack/' + timeTrackId,
  getUserTimePeriod: (userId: string, endDate: string, startDate: string) =>
    apiBase + '/timetrack/' + userId + '/timePeriod?endDate=' + endDate + '&startDate=' + startDate,
  getUserMissingTimetracks: (userId: string, endDate: string, startDate: string) =>
    apiBase +
    '/timetrack/missing/' +
    userId +
    '/timePeriod?endDate=' +
    endDate +
    '&startDate=' +
    startDate,
  getProjectMissingTimetracks: (projectId: string, startDate: string, endDate: string) =>
    apiBase +
    '/timetrack/missing/project?projectId=' +
    projectId +
    '&startDate=' +
    startDate +
    '&endDate=' +
    endDate,

  // reports
  getTimeTrackReports: apiBase + '/reports/timetracks',
  getAbsencesReports: apiBase + '/reports/absences',
  getWorkingHoursPdf: (projectId: string, endDate: string, startDate: string) =>
    apiBase +
    '/timetrack/' +
    projectId +
    '/timePeriod/report?endDate=' +
    endDate +
    '&startDate=' +
    startDate,

  // absences
  getAbsence: (absenceId: number) => apiBase + '/absences/' + absenceId,
  getAbsencesForUser: (userId: string) => apiBase + '/absences/user/' + userId,
  getAbsencesForProject: (projectId: string, startDate?: string, endDate?: string) =>
    apiBase +
    `/absences/project?projectId=${projectId}${startDate ? '&startDate=' + startDate : ''}${
      endDate ? '&endDate=' + endDate : ''
    }`,
  getSumAbsencesForProject: (projectId: string, startDate?: string, endDate?: string) =>
    apiBase +
    `/absences/sum/project?projectId=${projectId}${startDate ? '&startDate=' + startDate : ''}${
      endDate ? '&endDate=' + endDate : ''
    }`,
  getAbsencesReasonForProject: (
    projectId: string,
    startDate?: string,
    endDate?: string,
    absenceReason?: AbsenceReason
  ) =>
    apiBase +
    `/absences/reason/${absenceReason}?projectId=${projectId}${
      startDate ? '&startDate=' + startDate : ''
    }${endDate ? '&endDate=' + endDate : ''}`,
  getAbsencesForClient: (clientId: string) => apiBase + '/absences/client/' + clientId,
  getAbsences: apiBase + '/absences',
  createAbsence: apiBase + '/absences',
  updateAbsence: (absenceId: number) => apiBase + '/absences/' + absenceId,
  deleteAbsence: (absenceId: number) => apiBase + '/absences/' + absenceId,

  // meetings
  getAllMeetings: apiBase + '/meeting',
  getUserAndSupervisorMeetings: (userId: string) =>
    apiBase + '/meeting/user_supervisor?id=' + userId,
  createMeeting: apiBase + '/meeting',
  deleteMeeting: (meetingId: number) => apiBase + '/meeting?id=' + meetingId,

  // holidays
  getHolidaysForCurrentYear: () => apiBase + '/holidays' + '/all',
  getAllHolidaysBetweenDates: (startDate: string, endDate: string) =>
    apiBase + `/holidays?startDate=${startDate}&endDate=${endDate}`,
}
