import { Pageable } from 'Types/Pagination'

const defaultPage: number = 0
const defaultPageSize: number = 10

export default {
  constructPagination: (pagination?: Pageable) => {
    const page = pagination?.page ? pagination.page : defaultPage
    const pageSize = pagination?.pageSize ? pagination.pageSize : defaultPageSize
    return '?page=' + page + '&size=' + pageSize
  },
}
