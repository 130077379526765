import { LocalStorageKeyEnum } from 'localStorage.enum'
import { IProjectShort, IProjectShortClass } from 'Types/Project/IProjectShort'

export enum ProjectRole {
  BACKEND_DEVELOPER,
  FRONTEND_DEVELOPER,
  TESTER,
  DESIGNER,
  DEVOPS,
  PM,
  OFFICE_MANAGEMENT,
}
export interface ITimetrackCreateInterface {
  id: number
  description: string
  workingHours: number
  chargeableHours: number
  startDate: Date
  endDate: Date
  projectRole: ProjectRole
  project: IProjectShort
  ignoreHoliday: boolean
}
export interface ITimetrackInterface {
  id: number
  description: string
  workingHours: number
  chargeableHours: number
  projectRole: ProjectRole
  project: IProjectShort
  startDate: Date
  endDate: Date
  ignoreHoliday: boolean
}
export interface ITimetrackInterfaceGet {
  id: number
  description: string
  workingHours: number
  chargeableHours: number
  date: Date
  projectRole: ProjectRole
  project: IProjectShort
}
export class CTimetrack {
  id: number = 0
  description: string = ''
  workingHours: number = 8
  chargeableHours: number = 8
  date: Date = new Date()
  projectRole: ProjectRole = ProjectRole.FRONTEND_DEVELOPER
  project: IProjectShort = new IProjectShortClass()
  startDate: Date = new Date()
  endDate: Date = new Date()
  ignoreHoliday: boolean = false
}
export interface ITimetrack {
  timePeriodWorkingHoursCount: number
  timeTrack: ITimetrackInterface[]
}
export interface ITimetrackGet {
  timePeriodWorkingHoursCount: number
  timeTrack: ITimetrackInterfaceGet[]
}

export class ITimetrackClass {
  timePeriodWorkingHoursCount: number
  timetrack: ITimetrackInterface[]
  constructor() {
    this.timePeriodWorkingHoursCount = 0
    this.timetrack = Array<ITimetrackInterface>()
  }
}

export class TimeTrackCreate {
  id?: number
  chargeableHours: number | null = 8
  startDate: Date = new Date()
  endDate: Date = new Date()
  description: string = ''
  projectId: number | null = null
  projectRole: ProjectRole | null = null
  userId: string | number | null = localStorage.getItem(LocalStorageKeyEnum.USERID)
  workingHours: number | null = 8
  ignoreHoliday: boolean = false
}
